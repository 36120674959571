import React, { useState } from 'react';
import moment from 'moment';
import { Alert, AlertDetail } from '../Typings/alertTypes';
import Button from './Button';
import { GeoPill } from './Pill';

type AlertCardProps = {
  alert: Alert;
  renderChannels: (alert: Alert | AlertDetail) => JSX.Element[];
  updateAlertDetailsDisplay: (alertId: string) => void;
};

const AlertCard = ({
  alert,
  renderChannels,
  updateAlertDetailsDisplay,
}: AlertCardProps): JSX.Element => {
  const [trunc, setTrunc] = useState(true);

  return (
    <div
      key={`${alert._id}alertCard`}
      id={alert._id}
      className={trunc ? 'card alertCard' : 'card alertCard elongated-text'}
      onKeyPress={() => setTrunc(!trunc)}
      onClick={() => setTrunc(!trunc)}
      role="button"
      aria-pressed={false}
      tabIndex={0} //eslint-disable-line
    >
      <div className="alertCardHeader">
        <div className="pillContainer">
          {alert.geo.coordinates.length ? (<GeoPill />) : null}
          {renderChannels(alert)}
        </div>
        <p className="timestamp">{moment(alert.sent).format('h:mm a')}</p>
      </div>
      <div className="alertCardHeader">
        <p className={trunc ? 'truncated-text' : 'alertContent'}>{alert.content}</p>
        <Button
          className="primary-button detailsButton"
          text="Details"
          onClick={() => updateAlertDetailsDisplay(alert._id)}
        />
      </div>
    </div>
  );
};

export default AlertCard;
