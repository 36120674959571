import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Components/Button';
import Token from './Token';
import { refreshToken } from './adminLogic';
import { TokenType } from '../../Typings/adminTypes';
import { Channel } from '../../Typings/channelTypes';
import { ToastType } from '../../Typings/toastTypes';
import type SocketController from '../../Utils/SocketController';
import { RootState } from '../../Redux/reducers';

type TokenProps = {
  darkMode?: boolean,
  contents: TokenType[],
  createToken: (
    name: string, dispatch: (data: { type: string, payload: Channel | ToastType | TokenType[] }) => void, socket: SocketController,
  ) => void
  reRender: boolean
  reRenderFunc: (bool: boolean) => void
  showDelete: (id: string, name: string) => void
};

export const validateToken = (token: string):boolean => {
  const ifNotOnlySpace = /[a-zA-Z0-9]/.test(token);
  if (!ifNotOnlySpace) return true;
  if (token === undefined || token === null || token === '') return true;
  return false;
};

const TokenContainer = ({
  darkMode, contents, createToken, reRender, reRenderFunc, showDelete,
}: TokenProps): JSX.Element => {
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value);
  };

  const handleCreateToken = () => {
    createToken(token, dispatch, socket);
    reRenderFunc(!reRender);
    setToken('');
  };

  const handleRefresh = (id: string, name: string) => {
    refreshToken({ id, name }, dispatch, socket);
  };

  return (
    <div className={darkMode ? 'tokenTableDark' : 'tokenTable'}>
      <section aria-label="Tokens on Admin Page" className="sectionHeading tokenHeading">
        <h1
          tabIndex={0} // eslint-disable-line
        >
          Tokens
        </h1>
      </section>
      <section className={darkMode ? 'tokenHeaderDark' : 'tokenHeader'}>
        <input
          id="token-name"
          className="tokenInput"
          type="text"
          aria-label="This is the 'token name' input field. To generate a token, please type in a 'token name' into this input field and the 'generate token' button will no longer be disabled." // eslint-disable-line
          placeholder="Token Name"
          onChange={handleChange}
          value={token}
        />
        <Button
          aria-label="Generate Token"
          disabled={validateToken(token)}
          onClick={() => handleCreateToken()}
          text="Generate Token"
          className="generateToken primary-button"
        />
      </section>
      <section className="tokenSection">
        {contents && contents.map((item: TokenType) => (
          <Token
            key={`${item._id}section`}
            name={item.name}
            token={item.token}
            showDelete={showDelete}
            tokenId={item._id}
            refresh={(handleRefresh)}
          />
        ))}
      </section>
    </div>
  );
};

export default TokenContainer;
