import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { Styles } from '../../Typings/styleTypes';

const handleChange = (
  color:string,
  setHeaderColor: (style: string) => void,
): boolean => {
  setHeaderColor(color);
  return true;
};

const getStyle = async (
  setHeaderColor: (color: string) => void,
  style: Styles,
  setStyle: (data:Styles) => void,
  setStyleExists: (exists: boolean) => void,
  dispatch: (action: { type: string; payload?: Styles[] }) => void,
): Promise<boolean> => {
  let response: AxiosResponse<Styles[]>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { response = await axios.get('/api/v2/core/styles/', config); } catch (err) {
    return false;
  }
  if (response.status === 200) {
    dispatch({ type: 'GET_STYLES', payload: response.data });
    if (response.data[0]) {
      setStyle({
        ...style,
        _id: response.data[0]._id,
        headerColor: response.data[0].headerColor,
        headerText: response.data[0].headerText,
        backColor: response.data[0].backColor,
        backText: response.data[0].backText,
      });
      setHeaderColor(response.data[0].headerColor);
    }
    if (!response.data.length) setStyleExists(false);
    else setStyleExists(true);
    return true;
  }
  return false;
};

const getLogo = async (
  dispatch: (action: { type: string; payload3?:string }) => void,
): Promise<boolean> => {
  let res: AxiosResponse<{ href: string }>;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try { res = await axios.get('/api/v2/core/styles/logo', config); } catch (err) {
    return false;
  }
  if (res.status === 200) {
    dispatch({ type: 'GET_LOGO', payload3: '/api/v2/core/styles/logo' });
    return true;
  }
  return false;
};

const uploadLogoFile = async (
  files: any, // eslint-disable-line
  setFiles: (file:any) => void, // eslint-disable-line
  dispatch: (action: { type: string; payload3?:string }) => void,
  setErrMessage: (errMess: string) => void,
): Promise<boolean> => {
  const token = sessionStorage.getItem('authToken');
  const addPayload = new FormData();
  const logoUploadConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token !== null ? token : ''}`,
    },
  };
  if (!process.env.testing) {
    let name = '';
    const ext = files[0].file.name.split('.')[1]; // eslint-disable-line
    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') name = 'logo.png';
    addPayload.append(name, files[0].file, files[0].file.name);
  }
  try {
    const res = await axios.post('/api/v2/core/styles/logo', addPayload, logoUploadConfig);
    setErrMessage('');
    if (res.status === 201) {
      dispatch({ type: 'UPDATE_LOGO', payload3: res.data }); // eslint-disable-line
      setFiles([]);
      getLogo(dispatch);
      return true;
    }
  } catch (err) {
    setErrMessage('Please only upload files with these extensions: .png, .jpg or .jpeg');
    setFiles([]);
    return false;
  }

  return false;
};

const updateStyles = async (
  id: string,
  e: React.MouseEvent<HTMLButtonElement> | undefined,
  data: Styles,
  headerColor: string,
  setStyle: (obj:Styles) => void,
  styleExists: boolean,
): Promise<boolean> => {
  if (e !== undefined) e.preventDefault();
  let res: AxiosResponse<Styles>;
  const stylesConfig = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` },
  };
  try {
    if (!styleExists) res = await axios.post('/api/v2/core/styles/', { headerColor }, stylesConfig);
    else res = await axios.put(`/api/v2/core/styles/${id}`, { headerColor }, stylesConfig);
    if (res.status === 200 || res.status === 201) {
      setStyle({
        ...data,
        headerColor,
      });
      return true;
    }
  } catch (err) {
    return false;
  }
  return false;
};

const stylesLogic = {
  handleChange,
  getStyle,
  getLogo,
  updateStyles,
  uploadLogoFile,
};

export default stylesLogic;
