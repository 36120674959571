import React, { useState } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pill from './Pill';
import { Channel } from '../Typings/channelTypes';
import { Dispatch, SetStateAction } from '../Typings/setStateAction';

type ChannelSelectorProps = {
  channels: Channel[];
  setChannelPills: React.Dispatch<React.SetStateAction<Channel[]>>;
  channelPills: Channel[];
  sender?: boolean;
  className?: string;
};

export const changeChannelArray = (
  selected: Channel,
  selectedChannels: Channel[],
  setSelectedChannels: Dispatch<SetStateAction<Channel[]>>,
  setChannelPills: Dispatch<SetStateAction<Channel[]>>,
): boolean => {
  let copySelectedChannels = [...selectedChannels],
    pillsArray = [...selectedChannels];
  const pos = copySelectedChannels.find(
    (channel) => channel.name === selected.name,
  );
  if (pos === undefined) {
    copySelectedChannels.push(selected);
    pillsArray.push(selected);
    setSelectedChannels(copySelectedChannels);
    setChannelPills(pillsArray);
    return true;
  }
  copySelectedChannels = copySelectedChannels.filter(
    (obj) => obj.name !== selected.name,
  );
  pillsArray = pillsArray.filter((obj) => obj.name !== selected.name);
  setSelectedChannels(copySelectedChannels);
  setChannelPills(pillsArray);
  return false;
};

export const ChannelSelector = ({
  channels,
  setChannelPills,
  channelPills,
  sender,
  className,
}: ChannelSelectorProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>(channelPills);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  let decodedChannels: string[] = [];
  if (sessionStorage.getItem('channels') !== null) {
    decodedChannels = JSON.parse(
      sessionStorage.getItem('channels') as string,
    ) as string[];
  }

  React.useEffect(() => {
    setSelectedChannels(channelPills);
  }, [channelPills]);

  const renderChannelOptions = (): JSX.Element => {
    const channelItems = () => {
      if (channels.length > 1) {
        return (
          <div className="dropdownChannel">
            {channels
              && channels.map((channel: Channel) => {
                if (sender && !decodedChannels?.includes(channel._id)) return null;
                return (
                  <DropdownItem
                    toggle={false}
                    key={`${channel.name}-dropDownItem`}
                    onClick={() => changeChannelArray(
                      channel,
                      selectedChannels,
                      setSelectedChannels,
                      setChannelPills,
                    )}
                  >
                    <div className="selectorItemGroup">
                      <div className="selectorCheck">
                        {selectedChannels.includes(channel) ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : (
                          <div className="emptyCheck" />
                        )}
                      </div>
                      <Pill
                        key={`${channel.name}-pill`}
                        content={channel.name}
                        backgroundColor={channel.style}
                        textColor={channel.color}
                      />
                    </div>
                  </DropdownItem>
                );
              })}
          </div>
        );
      }
      return (
        <DropdownItem toggle key="-dropDownItem">
          <div className="selectorItemGroup">
            <div className="selectorCheck">No channels</div>
          </div>
        </DropdownItem>
      );
    };
    return <>{channelItems()}</>;
  };

  const menuModifier = {
    setMaxHeight: {
      enabled: true,
      order: 890,
      fn: (data: any) => ({// eslint-disable-line
        ...data,
        styles: {// eslint-disable-line
          ...data.styles,
          overflow: 'auto',
          maxHeight: '100px',
        },
      }),
    },
  };

  return (
    <div aria-label="Select Channels on Send Alerts Page" className={className !== '' || className !== undefined ? className : 'channelSelector'}>
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggleDropDown}>
        <DropdownToggle className="primary-button" color="black" caret>
          Select Channels
        </DropdownToggle>
        <DropdownMenu persist flip={false} modifiers={menuModifier}>
          {renderChannelOptions()}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ChannelSelector;
