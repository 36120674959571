import moment from 'moment';
import React from 'react';
import { AlertDetail } from '../Typings/alertTypes';
import Donut from './DonutChart';

type SingleAlertAnalyticProps = {
  alertDetail: AlertDetail
  renderChannels: (alertDetail: AlertDetail) => JSX.Element[],
};

const SingleAlertAnalytics = ({ alertDetail, renderChannels }: SingleAlertAnalyticProps): JSX.Element => {
  const acknowledgedData = [
    { value: alertDetail.recipients.length, stroke: '#b1d4e9' }, // should be larger value i.e: total sent
    { value: alertDetail.acknowledged.length, stroke: '#0075c9' }, // should be smaller value i.e: total acknowledged
  ];

  const receivedDetails = [
    { value: alertDetail.recipients.length, stroke: '#f76b1c' },
    { value: alertDetail.devicesReceived.length, stroke: '#ec5a08' },
  ];

  const allAlertsAcknowledged = [
    { value: alertDetail.devicesReceived.length, stroke: '#0075c9' },
    { value: alertDetail.acknowledged.length, stroke: '#0075c9' },
  ];

  const allAlertsReceived = [
    { value: alertDetail.recipients.length, stroke: '#f76b1c' },
    { value: alertDetail.devicesReceived.length, stroke: '#f76b1c' },
  ];

  const alertNotSentToAnyDevices = [
    { value: alertDetail.recipients.length, stroke: '#EFEFEF' },
    { value: alertDetail.acknowledged.length, stroke: '#EFEFEF' },
  ];

  return (
    <div className="singleAlertContainer">
      <div className="card singleAlertHeaderCard">
        <div className="singleAlertHeader">

          <p className="singleAlertTitle">
            {alertDetail.content}
          </p>
          <div className="singleAlertPillContainer">{renderChannels(alertDetail)}</div>
        </div>
        <div className="singleAlertHeader">
          <p className="timestamp">
            {moment(alertDetail.sent).format('MMMM Do YYYY, h:mm a')}
          </p>
        </div>
      </div>
      <div className="singleAlertContentContainer">
        <div className="donutStatsContainer">
          {alertDetail.recipients.length === 0
            ? (
              <>
                <Donut data={alertNotSentToAnyDevices} heading="Acknowledged" />
                <Donut data={alertNotSentToAnyDevices} heading="Acknowledged" />
              </>
            )
            : (
              <>
                <Donut
                  data={alertDetail.recipients.length === alertDetail.acknowledged.length ? allAlertsAcknowledged : acknowledgedData}
                  heading="Acknowledged"
                />
                <Donut
                  data={alertDetail.recipients.length === alertDetail.devicesReceived.length ? allAlertsReceived : receivedDetails}
                  heading="Received"
                />
              </>
            )}
        </div>
        <div className="singleAlertStatContainer">
          <div className="card singleAlertCard">
            <p className="singleAlertCardNumber">
              {alertDetail.devicesReceived.length}
            </p>
            <p className="singleAlertCardNumberFont">
              devices received selected alert
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SingleAlertAnalytics;
