import React, { useEffect } from 'react';
import {
  LatLng,
  LatLngBounds, LatLngExpression, Point,
} from 'leaflet';
import moment from 'moment';
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Marker,
  Circle,
  Popup,
  useMapEvents,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Devices } from '../../Typings/deviceTypes';
import 'leaflet-draw/dist/leaflet.draw.css';
import { CreateShape } from '../../Typings/CreateShape';
import { Channel } from '../../Typings/channelTypes';
import senderUtils from '../../Utils/senderUtils';
import { RootState } from '../../Redux/reducers';
import { fetchRegisteredDevices } from '../../Utils/deviceUtils';
import store from '../../Redux/store';

export const renderDevices = (devices: Devices[]): (JSX.Element | null)[] => {
  const deviceList = devices.map((device) => {
    if (device.loc.coordinates.length) {
      const coords = device.loc.coordinates;
      let usrName = 'Guest';
      if (device.profile !== undefined && device.profile !== null && device.profile.name) {
        usrName = device.profile.name;
      }
      return (
        <Marker key={device._id} position={coords as LatLngExpression}>
          <Popup>
            Username:
            {' '}
            {usrName}
            <br />
            Last Updated:
            {' '}
            {moment(device.updated_at).format('MMMM Do YYYY, h:mm a')}

          </Popup>
        </Marker>
      );
    }
    return null;
  });
  return deviceList;
};

export const renderGeoChannelCircles = (channels: Channel[]): (JSX.Element | null)[] => {
  const circles = channels.map((channel) => {
    if (channel.type === 'geofence' && channel.coordinates && channel.circle) {
      return (
        <Circle
          key={channel._id}
          pathOptions={{ color: channel.style }}
          center={[channel.coordinates.lat, channel.coordinates.lon]}
          radius={channel.circle.radius}
        >
          <Popup>{channel.name}</Popup>
        </Circle>
      );
    }
    return null;
  });
  return circles;
};

const MapEvents = (
  { persistMap, setCookie }: {
    persistMap: boolean,
    setCookie: (name: 'mapZoom' | 'mapCenterPnt', value: number | LatLng) => void
  }): JSX.Element | null => {
  const dispatch = useDispatch();
  const map = useMapEvents({
    moveend: () => {
      const mapCPnt = map.getCenter();
      if (persistMap) {
        setCookie('mapCenterPnt', map.getCenter());
        dispatch({ type: 'MAP_CENTER', payload: [mapCPnt.lat, mapCPnt.lng] });
      }
    },
    zoomend: () => {
      setCookie('mapZoom', map.getZoom());
      if (persistMap) dispatch({ type: 'MAP_ZOOM', payload: map.getZoom() });
    },
  });
  return null;
};

const SenderMap = ({
  incomingDevices, userLocation, channelPills, persistMap,
}:
{
  incomingDevices: Devices[], userLocation: number[], channelPills: Channel[], persistMap: boolean,
}): JSX.Element | null => {
  const {
    bounds, radius, centerPnt, reRenderMap, selectedMapDevices, shapes, mostRecentShape, polyPoints,
  } = useSelector((state: RootState) => state.sender);
  let { mapZoom } = useSelector((state: RootState) => state.sender), mcp = userLocation as LatLngExpression;
  const [cookies, setCookie] = useCookies(['mapZoom', 'mapCenterPnt']);
  const dispatch = useDispatch();

  if (cookies.mapZoom) mapZoom = cookies.mapZoom;
  if (cookies.mapCenterPnt) mcp = cookies.mapCenterPnt;

  useEffect(() => {
    if (bounds) {
      senderUtils.findAndSelectDevices(incomingDevices, bounds, null, null, [], selectedMapDevices, dispatch, shapes, mostRecentShape);
    }
  }, [bounds]);

  useEffect(() => {
    if (centerPnt && radius) {
      senderUtils.findAndSelectDevices(incomingDevices, null, centerPnt, radius, [], selectedMapDevices, dispatch, shapes, mostRecentShape);
    }
  }, [centerPnt, radius]);

  useEffect(() => {
    if (polyPoints) {
      senderUtils.findAndSelectDevices(incomingDevices, null, null, null, polyPoints, selectedMapDevices, dispatch, shapes, mostRecentShape);
    }
  }, [polyPoints]);

  const onCreate = (e: CreateShape) => {
    if (e.layerType === 'rectangle') {
      const shapeBounds: LatLngBounds = e.layer.getBounds() as LatLngBounds; //eslint-disable-line
      dispatch({ type: 'ADD_SHAPE', payload: e.layer._bounds._northEast as unknown as LatLng });
      dispatch({ type: 'MOST_RECENT_SHAPE', payload: e.layer._bounds._northEast as unknown as LatLng });
      dispatch({ type: 'SET_GEO_MAP', payload: { bounds: shapeBounds, radius: 0, centerPnt: null } });
    }
    if (e.layerType === 'polygon') {
      const polyLatLngs = e.layer._latlngs[0] as LatLng[];
      const newPoints = polyLatLngs.map(Object.values);
      dispatch({ type: 'ADD_SHAPE', payload: e.layer._bounds._northEast as unknown as LatLng });
      dispatch({ type: 'MOST_RECENT_SHAPE', payload: e.layer._bounds._northEast as unknown as LatLng });
      dispatch({
        type: 'SET_GEO_MAP',
        payload: {
          bounds: null, radius: null, centerPnt: null, polyPoints: newPoints,
        },
      });
    }
    if (e.layerType === 'circle') {
      const circleCenter: Point = e.layer.getLatLng() as Point; //eslint-disable-line
      const circleRadius: number = e.layer.getRadius() as number; //eslint-disable-line
      dispatch({ type: 'ADD_SHAPE', payload: e.layer._latlng as unknown as LatLng });
      dispatch({ type: 'MOST_RECENT_SHAPE', payload: e.layer._latlng as unknown as LatLng });
      dispatch({ type: 'SET_GEO_MAP', payload: { bounds: null, radius: circleRadius, centerPnt: circleCenter } });
    }
  };

  const onDelete = (e: any, reRender?: boolean) => {//eslint-disable-line
    const newSelectedDevices: Devices[] = [];
    const reduxState = store.getState();
    let filteredShapeArr = reduxState.sender.shapes;
    if (e.layers) {
      const shapeValues: any = Object.values(e.layers._layers);//eslint-disable-line
      for (let i = 0; i < shapeValues.length; i += 1) {
        filteredShapeArr = filteredShapeArr.filter((singleShape) => {
          if (shapeValues[i]._latlng) return singleShape.lastCenter !== shapeValues[i]._latlng;
          if (shapeValues[i]._bounds._northEast) return singleShape.lastCenter !== shapeValues[i]._bounds._northEast;
          return false;
        });
      }
    }

    if (reRender) {
      dispatch({ type: 'SELECTED_DEVICES', payload: [] });
      dispatch({ type: 'REMOVE_SHAPE', payload: [] });
      setTimeout(() => {
        fetchRegisteredDevices(dispatch);
      }, 200);
    } else {
      filteredShapeArr.forEach((neededShape: { lastCenter: LatLng, associatedDevices?: Devices[] }) => {
        newSelectedDevices.push(...neededShape.associatedDevices as Devices[]);
      });
      dispatch({ type: 'SELECTED_DEVICES', payload: [...new Set(newSelectedDevices) as unknown as Devices[]] });
      dispatch({ type: 'REMOVE_SHAPE', payload: filteredShapeArr });
      dispatch({ type: 'MOST_RECENT_SHAPE', payload: [] });
    }
    dispatch({ type: 'RESET_MAP_RENDER', payload: false });
    dispatch({
      type: 'SET_GEO_MAP',
      payload: {
        bounds: null, radius: null, centerPnt: null, polyPoints: null,
      },
    });
  };

  useEffect(() => {
    if (reRenderMap) {
      onDelete({}, reRenderMap);
    }
  }, [reRenderMap]);

  return (
    !reRenderMap
      ? (
        <MapContainer
          key={userLocation[0]}
          className="map_container"
          center={mcp}
          zoom={mapZoom}
          scrollWheelZoom
          style={{ height: '50vh' }}
          zoomControl
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={onCreate}
              onDeleted={onDelete}
              draw={{
                polygon: true,
                rectangle: true,
                polyline: false,
                circle: true,
                circlemarker: false,
                marker: false,
              }}
            />
          </FeatureGroup>
          {renderDevices(incomingDevices)}
          {renderGeoChannelCircles(channelPills)}
          <MapEvents persistMap={persistMap} setCookie={setCookie} />
        </MapContainer>
      )

      : null);
};
export default SenderMap;
