import DistrictSocketHandler from '../../Utils/DistrictSocketHandler';

const sendItem = (
  type: 'alert' | 'CAP' | 'events',
  id: string,
  campusIds: number[],
  districtSocket: DistrictSocketHandler,
): void => {
  const userId = sessionStorage.getItem('authorizedUser');
  districtSocket.triggerItem({
    type,
    id,
    campusIds,
    userId,
  });
};

const mobileActivationLogic = {
  sendItem,
};

export default mobileActivationLogic;
