import React, { useState } from 'react';
import Card from '../../Components/Card';

type TokenProps = {
  name: string,
  token: string,
  tokenId: string,
  showDelete: (id: string, name: string) => void,
  refresh: (id: string, name: string) => void
};
const Token = ({
  name, token, showDelete, tokenId, refresh,
}: TokenProps): JSX.Element => {
  const [tokenState, setTokenState] = useState('******');

  const tokenContent = [
    {
      heading: '',
      data: [],
    },
    {
      heading: name,
      data: [tokenState],
    },
    {
      heading: '',
      data: [],
    },
  ];
  return (
    <Card
      id={tokenId}
      name={name}
      handleDelete={() => showDelete(tokenId, name)}
      handleEdit={() => refresh(tokenId, name)}
      content={tokenContent}
      type="token"
      token={token}
      setTokenState={setTokenState}
    />
  );
};

export default Token;
