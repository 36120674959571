import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Router from './Router';
import SocketController from './Utils/SocketController';
import { fetchRegisteredDevices } from './Utils/deviceUtils';
import { RootState } from './Redux/reducers/index';
import DistrictSocketHandler from './Utils/DistrictSocketHandler';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const loggedIn = sessionStorage.getItem('authToken');
  const { socket } = useSelector((state: RootState) => state.socket);
  const {
    campuses, campusAlerts, events, capMessages,
  } = useSelector((state: RootState) => state.district);
  const isDistrictDataAvailable = campuses.length > 0 || campusAlerts.length > 0 || events.length > 0 || capMessages.length > 0;

  const decodedPermissions: string | null = sessionStorage.getItem('permissions');
  let parsedPermissions: string[];
  if (decodedPermissions !== null) {
    parsedPermissions = JSON.parse(decodedPermissions) as string[];
  } else parsedPermissions = [''];

  React.useEffect(() => {
    if (!socket.emitCurrent) {
      dispatch({ type: 'NEW_SOCKET', payload: new SocketController(dispatch) });
      if (loggedIn && socket.emitCurrent) {
        socket.emitCurrent();
      }
    }
  }, []);

  React.useEffect(() => {
    if (loggedIn && socket.emitCurrent) {
      socket.emitCurrent();
      socket.emitActiveUsers();
      fetchRegisteredDevices(dispatch);
    }
  }, [loggedIn, socket]);

  React.useEffect(() => {
    if (loggedIn) dispatch({ type: 'NEW_DISTRICT_SOCKET', payload: new DistrictSocketHandler(dispatch) });
  }, [loggedIn]);

  return (
    <div id="App">
      <Router parsedPermissions={parsedPermissions} isDistrictDataAvailable={isDistrictDataAvailable} />
    </div>
  );
};
export default withRouter(connect(null, null)(App));
