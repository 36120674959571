import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { RootState } from '../../Redux/reducers';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import Status from '../../Components/Status';
import addEditUserLogic from './AddEditUserLogic';
import { getRoles, getAccessControls } from '../Admin/adminLogic';
import channelLogic from '../Channels/channelLogic';
import userLogic from './usersLogic';
import { Role, AccessControls } from '../../Typings/adminTypes';
import { Channel } from '../../Typings/channelTypes';
import {
  CampusAlertType, CampusType, CapMessageType, MobileActivation, MobileEventType, User,
} from '../../Typings/userTypes';

const usernameExists = (
  id: string | undefined,
  username: string,
  users: User[],
) => {
  if (!users || !users.length) {
    return false;
  }

  for (let i = 0; i < users.length; i += 1) {
    if (users[i].username === username) {
      if (id) {
        if (id && users[i]._id !== id) {
          // if the username matches another user, but the id's also match then this user is just being edited
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
};

interface ComparisonTypes {
  campuses: string[], campusAlerts: number[], capMessages: number[], districtEvents: string[],
}

export const validateUser = (
  username: string,
  password: string,
  confirmedPassword: string,
  roleStateName: string,
  id: string | undefined,
  users: User[],
): boolean => {
  const testUserOnlySpace = /[a-zA-Z0-9]/.test(username);
  const testPassOnlySpace = /[a-zA-Z0-9]/.test(password);
  if (id === undefined) {
    if (!testPassOnlySpace) return true;
    if (
      password === undefined
      || password === null
      || password === ''
      || password.length < 8
    ) return true;
  }
  if (!testUserOnlySpace) return true;
  if (username === undefined || username === null || username === '' || username.length < 5) return true;
  if (roleStateName === 'Select A Role') return true;
  if (password !== confirmedPassword) return true;
  if (usernameExists(id, username, users)) return true;
  return false;
};

const CampusCard = ({ campus, onClick }: { campus: { id: string, name: string, address: string }, onClick: () => void }) => (
  <div>
    <button
      type="button"
      className="district-option campusOption"
      onClick={onClick}
    >
      <p>{campus.name}</p>
      <p className="campusAddress">{campus.address}</p>
    </button>
  </div>
);

const AddEditUser = ({
  id,
  toggle,
  modalExpansion,
  setModalExpansion,
}: {
  toggle: () => void;
  id?: string;
  modalExpansion: boolean;
  setModalExpansion: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const [errorState, setErrorState] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [roleState, setRoleState] = useState({ id: '', name: 'Select A Role' });
  const [accessControlState, setAccessControlState] = useState<Array<string>>(
    [],
  );
  const [canEditUsername, setCanEditUsername] = useState(true);
  const dispatch = useDispatch();
  const [requiredUsernameInput, setRequiredUsernameInput] = useState(false);
  const [requiredPasswordInput, setRequiredPasswordInput] = useState(false);
  const [mobileActivation, setMobileActivation] = useState<MobileActivation>({
    canActivate: false,
    campusAlerts: [],
    campuses: [],
    capMessages: [],
    districtEvents: [],
  });
  const [comparisons, setComparisons] = useState<ComparisonTypes>({
    campusAlerts: [],
    campuses: [],
    capMessages: [],
    districtEvents: [],
  });
  const [campusAlertOptions, setCampusAlertOptions] = useState<CampusAlertType[]>([]);
  const [campusOptions, setCampusOptions] = useState<CampusType[]>([]);
  const [capMsgOptions, setCapMsgOptions] = useState<CapMessageType[]>([]);
  const [eventOptions, setEventOptions] = useState<MobileEventType[]>([]);
  const [requiredConfirmedPasswordInput, setRequiredConfirmedPasswordInput] = useState(false);

  const { roles, accessControls, rolesVisited } = useSelector(
    (state: RootState) => state.admin,
  );
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const { users, usersVisited } = useSelector(
    (state: RootState) => state.users,
  );
  const { channels, channelsVisited } = useSelector(
    (state: RootState) => state.channel,
  );
  const {
    campuses, campusAlerts, capMessages, events,
  } = useSelector((state: RootState) => state.district);
  const [channelState, setChannelState] = useState<Array<string>>([]);

  useEffect(() => {
    if (id && (!usersVisited || !users.length)) {
      userLogic.getUsers(dispatch);
    }
    if (!rolesVisited || !roles.length) {
      getRoles(dispatch);
    }
    if (!channelsVisited || !channels.length) {
      channelLogic.getChannels(dispatch);
    }
    if (!accessControls.length) {
      getAccessControls(dispatch);
    }
  }, []);

  useEffect(() => {
    const editingUser = users.find((cur: User) => cur._id === id);
    setModalExpansion(false);
    if (editingUser !== undefined) {
      setUsername(editingUser.username);
      if (editingUser.username === 'GUEST' || editingUser.username === 'admin') setCanEditUsername(false);
      if (editingUser.email === null) setEmail('');
      else setEmail(editingUser.email);
      setIsChecked(editingUser.admin);
      const userRole = roles.find((r: Role) => r._id === editingUser.role);
      if (userRole) setRoleState({ id: userRole._id, name: userRole.name });
      const userChan: string[] = editingUser.channels.map((c) => {
        if (c.channel._id) return c.channel._id; // on initial page load, we use User returned from API
        return c.channel as unknown as string; // on 'updatedUser' socket emit, we use User returned from mongoose's findOneAndReplace
      });
      setChannelState(userChan);
      setAccessControlState(editingUser.accessControls);
      if (editingUser.mobileActivation?.canActivate) {
        setMobileActivation(editingUser.mobileActivation);
        setModalExpansion(true);
        setComparisons({
          campuses: editingUser.mobileActivation.campuses.map((c) => c.id),
          campusAlerts: editingUser.mobileActivation.campusAlerts.map((c) => c.id),
          capMessages: editingUser.mobileActivation.capMessages.map((c) => c.id),
          districtEvents: editingUser.mobileActivation.districtEvents.map((e) => e.id),
        });
      } else setModalExpansion(false);
    }
  }, [id, users, roles]);

  useEffect(() => {
    if (campuses?.length) {
      if (comparisons.campuses.length) {
        setCampusOptions(campuses.filter(
          (c) => !comparisons.campuses.includes(c.id),
        ).sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)));
      } else setCampusOptions(campuses.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)));
    }
    if (campusAlerts?.length) {
      if (comparisons.campusAlerts.length) setCampusAlertOptions(campusAlerts.filter((c) => !comparisons.campusAlerts.includes(c.id)));
      else setCampusAlertOptions(campusAlerts);
    }
    if (capMessages?.length) {
      if (comparisons.capMessages.length) setCapMsgOptions(capMessages.filter((c) => !comparisons.capMessages.includes(c.id)));
      else setCapMsgOptions(capMessages);
    }
    if (events?.length) {
      if (comparisons.districtEvents.length) {
        setEventOptions(events.filter(
          (e) => !comparisons.districtEvents.includes(e.id),
        ).sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)));
      } else setEventOptions(events.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)));
    }
  }, [campuses, campusAlerts, events, capMessages, comparisons]);

  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const renderRolesItems = (): JSX.Element[] => {
    const roleItems = roles.map((role: Role) => (
      <DropdownItem
        key={role._id}
        onClick={() => {
          setRoleState({ name: role.name, id: role._id });
        }}
      >
        {role.name}
      </DropdownItem>
    ));
    return roleItems;
  };

  const dropdownClick = () => {
    if (roleState.name === 'Select A Role') setRoleState({ name: '', id: '' });
  };

  const renderChannelItems = (itemType: string): JSX.Element[] | null => {
    if (!channels.length) return null;
    const items = channels.map((chan: Channel) => (
      <div className="form-check" key={chan._id}>
        <input
          className="checkbox"
          type="checkbox"
          id={chan._id}
          checked={username === 'admin' || addEditUserLogic.isItemChecked(chan, itemType, channelState, accessControlState)}
          onChange={() => addEditUserLogic.arrayofChannels(chan, channelState, setChannelState)}
          aria-label={addEditUserLogic.isItemChecked(chan, itemType, channelState, accessControlState)
            ? `Do you want to uncheck ${chan.name} channel for this user?`
            : `Do you want to check ${chan.name} channel for this user?`}
          disabled={username === 'admin' && roleState.name === 'admin'}
        />
        <label htmlFor="admin" className="form-checkbox">
          {chan.name}
        </label>
      </div>
    ));
    return items;
  };

  const renderAccessControls = (itemType: string): JSX.Element[] | null => {
    if (!accessControls.length) return null;
    const items = accessControls.map((item: AccessControls) => (
      <div className="form-check" key={item._id}>
        <input
          className="checkbox"
          type="checkbox"
          id={item._id}
          checked={username === 'admin' || addEditUserLogic.isItemChecked(item, itemType, channelState, accessControlState)}
          onChange={() => addEditUserLogic.arrayofAccessControls(item, accessControlState, setAccessControlState)}
          aria-label={addEditUserLogic.isItemChecked(item, itemType, channelState, accessControlState)
            ? `Do you want to uncheck ${item.name} access control for this user?`
            : `Do you want to check ${item.name} access control for this user?`}
          disabled={username === 'admin' && roleState.name === 'admin'}
        />
        <label htmlFor="admin" className="form-checkbox">
          {item.name}
        </label>
      </div>
    ));
    return items;
  };

  const renderCampuses = (items: CampusType[], renderType: string): JSX.Element | null => (items.length ? (
    <>
      {
        items.map((campus) => (
          <CampusCard
            key={`${campus.id}-${campus.address}`}
            campus={campus}
            onClick={() => {
              if (renderType === 'options') {
                setMobileActivation({ ...mobileActivation, campuses: [...mobileActivation.campuses, campus] });
                setComparisons({ ...comparisons, campuses: [...comparisons.campuses, campus.id] });
              } else {
                setMobileActivation({ ...mobileActivation, campuses: mobileActivation.campuses.filter((c) => c !== campus) });
                setComparisons({ ...comparisons, campuses: comparisons.campuses.filter((c) => c !== campus.id) });
              }
            }}
          />
        ),
        )
      }
    </>
  ) : null);

  const renderCampusAlerts = (items: CampusAlertType[], itemType: string): JSX.Element | null => (items.length ? (
    <>
      {
        items.map((cAlert) => (
          <div key={cAlert.label}>
            <button
              type="button"
              className="district-option"
              onClick={() => {
                if (itemType === 'options') {
                  setMobileActivation({ ...mobileActivation, campusAlerts: [...mobileActivation.campusAlerts, cAlert] });
                  setComparisons({ ...comparisons, campusAlerts: [...comparisons.campusAlerts, cAlert.id] });
                } else {
                  setMobileActivation({ ...mobileActivation, campusAlerts: mobileActivation.campusAlerts.filter((c) => c !== cAlert) });
                  setComparisons({ ...comparisons, campusAlerts: comparisons.campusAlerts.filter((c) => c !== cAlert.id) });
                }
              }}
            >
              {cAlert.label}
            </button>
          </div>
        ),
        )
      }
    </>
  ) : null);

  const renderCapMessages = (items: CapMessageType[], itemType: string): JSX.Element | null => (items.length ? (
    <>
      {items.map((cap) => (
        <div key={`${cap.label}-${cap.id}`}>
          <button
            type="button"
            className="district-option"
            onClick={() => {
              if (itemType === 'options') {
                setMobileActivation({ ...mobileActivation, capMessages: [...mobileActivation.capMessages, cap] });
                setComparisons({ ...comparisons, capMessages: [...comparisons.capMessages, cap.id] });
              } else {
                setMobileActivation({ ...mobileActivation, capMessages: mobileActivation.capMessages.filter((c) => c !== cap) });
                setComparisons({ ...comparisons, capMessages: comparisons.capMessages.filter((c) => c !== cap.id) });
              }
            }}
          >
            {cap.label}
          </button>
        </div>
      ),
      )}
    </>
  ) : null);

  const renderMobileEvents = (items: MobileEventType[], itemType: string): JSX.Element | null => (items.length ? (
    <>
      {items.map((event) => (
        <div key={`${event.label}-${event.id}`}>
          <button
            type="button"
            className="district-option"
            onClick={() => {
              if (itemType === 'options') {
                setMobileActivation({ ...mobileActivation, districtEvents: [...mobileActivation.districtEvents, event] });
                setComparisons({ ...comparisons, districtEvents: [...comparisons.districtEvents, event.id] });
              } else {
                setMobileActivation({ ...mobileActivation, districtEvents: mobileActivation.districtEvents.filter((e) => e !== event) });
                setComparisons({ ...comparisons, districtEvents: comparisons.districtEvents.filter((e) => e !== event.id) });
              }
            }}
          >
            {event.label}
          </button>
        </div>
      ),
      )}
    </>
  ) : null);

  const renderSection = (
    optionType: string,
    optionItems: CampusAlertType[] | CampusType[] | CapMessageType[] | MobileEventType[],
    selectedItems: CampusAlertType[] | CampusType[] | CapMessageType[] | MobileEventType[],
  ) => (
    <div>
      <p className="input-sub-label">{optionType}</p>
      <p className="input-subtext">
        Click option to select/deselect. User will be able to send
        {' '}
        {
          optionType === 'Campuses' ? 'to' : ''
        }
        {' '}
        selected
        {' '}
        {optionType}
        .
      </p>
      <div className="mobileActivation">
        <div className="mobileActivationOptions">
          <p className="select-status">Unselected</p>
          {optionType === 'Campuses' && renderCampuses(optionItems as CampusType[], 'options')}
          {optionType === 'Campus Alerts' && renderCampusAlerts(optionItems as CampusAlertType[], 'options')}
          {optionType === 'CAP Messages' && renderCapMessages(optionItems as CapMessageType[], 'options')}
          {optionType === 'Events' && renderMobileEvents(optionItems as MobileEventType[], 'options')}
        </div>
        <div className="section-divider" />
        <div className="mobileActivationOptions">
          <p className="select-status">Selected</p>
          {optionType === 'Campuses' && renderCampuses(selectedItems as CampusType[], 'selections')}
          {optionType === 'Campus Alerts' && renderCampusAlerts(selectedItems as CampusAlertType[], 'selections')}
          {optionType === 'CAP Messages' && renderCapMessages(selectedItems as CapMessageType[], 'selections')}
          {optionType === 'Events' && renderMobileEvents(selectedItems as MobileEventType[], 'selections')}
        </div>
      </div>
    </div>
  );

  const renderMobileActivation = (): JSX.Element => (
    <>
      {campusOptions.length || mobileActivation.campuses ? renderSection(
        'Campuses', campusOptions, mobileActivation.campuses,
      ) : null}
      {campusAlertOptions.length || mobileActivation.campusAlerts ? renderSection(
        'Campus Alerts', campusAlertOptions, mobileActivation.campusAlerts,
      ) : null}
      {capMsgOptions.length || mobileActivation.capMessages ? renderSection(
        'CAP Messages', capMsgOptions, mobileActivation.capMessages,
      ) : null}
      {eventOptions.length || mobileActivation.districtEvents ? renderSection(
        'Events', eventOptions, mobileActivation.districtEvents,
      ) : null}
    </>
  );

  const watchUsernameEdit = (usernameEdit: string) => {
    setUsername(usernameEdit);
    if (usernameExists(id, usernameEdit, users)) {
      setErrorState('A user with this username already exists');
    } else {
      setErrorState('');
    }
  };

  const handleUsernameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    watchUsernameEdit(e.target.value);
    if (e.target.value === '') setRequiredUsernameInput(true);
    if (e.target.value !== '') setRequiredUsernameInput(false);
    if (e.target.value.length < 5) {
      setRequiredUsernameInput(true);
    }
  };

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorState('');
    if (e.target.value === '') setRequiredPasswordInput(true);
    if (e.target.value !== '') setRequiredPasswordInput(false);
    if (e.target.value.length < 8) {
      setRequiredPasswordInput(true);
    }
  };

  const handleConfirmedPasswordInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmedPassword(e.target.value);
    if (e.target.value === '') setRequiredConfirmedPasswordInput(true);
    if (e.target.value !== '' && password !== e.target.value) {
      setRequiredConfirmedPasswordInput(true);
      setErrorState('Passwords do not match.');
    }
    if (e.target.value !== '' && password === e.target.value) {
      setRequiredConfirmedPasswordInput(false);
      setErrorState('');
    }
  };

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  ) => {
    if (!mobileActivation.canActivate) {
      mobileActivation.campusAlerts = [];
      mobileActivation.campuses = [];
      mobileActivation.capMessages = [];
    }
    if (id) {
      setModalExpansion(false);
      addEditUserLogic.submitEditUser(
        e,
        id,
        channelState,
        channels,
        isChecked,
        accessControlState,
        roleState,
        username,
        password,
        email,
        mobileActivation,
        toggle,
        dispatch,
        setErrorState,
        socket,
      );
    } else {
      setModalExpansion(false);
      addEditUserLogic.submitAddUser(
        e,
        setErrorState,
        username,
        email,
        password,
        roleState,
        channelState,
        channels,
        accessControlState,
        accessControls,
        isChecked,
        mobileActivation,
        dispatch,
        toggle,
        socket,
      );
    }
  };

  return (
    <main className="outerFormContainer">
      {errorState.length ? <Status type="error" message={errorState} /> : null}
      <div className="container-no-gutters">
        <div className="row">
          <div className="col">
            <div className="form-group requiredFormGroupLabel">
              <Input
                disabled={!canEditUsername}
                htmlFor="Username"
                className={
                  requiredUsernameInput
                    ? 'form-control requiredInput'
                    : 'form-control'
                }
                type="username"
                placeholder="Username"
                value={username}
                onChange={
                  canEditUsername ? (e) => handleUsernameOnChange(e) : () => true
                }
                label={requiredUsernameInput ? 'Username' : 'Username *'}
                ariaLabel={id
                  ? `${username} is the current username. Do you wish to change it?`
                  : 'This is the required username input field. Please type in username here and submit button will no longer be disabled.'}
                required={!!requiredUsernameInput}
              />
            </div>
            <p className={requiredUsernameInput ? 'minorPrint' : 'hidden'}>Username must be at least 5 characters long.</p>
            {
              username !== 'GUEST' ? (
                <>
                  <div className="form-group">
                    <Input
                      htmlFor="Email"
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label="Email"
                      ariaLabel={email.length ? `${email} is the current email. Do you wish to change it?`
                        : 'This is the email input field. Please type in email here.'}
                    />
                  </div>
                  <div className="form-group requiredFormGroupLabel">
                    <div className={requiredPasswordInput ? 'requiredLabelRed input-label' : 'input-label'}>
                      {id !== undefined || requiredPasswordInput ? 'Password' : 'Password *'}
                    </div>
                    <p className={requiredPasswordInput ? 'minorPrint' : 'hidden'}>Password must be at least 8 characters long.</p>
                    <input
                      className={requiredPasswordInput ? 'form-control requiredInput' : 'form-control'}
                      type="password"
                      placeholder="********"
                      value={password}
                      onChange={(e) => handlePasswordInput(e)}
                      aria-label={id
                        ? 'You may change your password in this input field'
                        : 'This is the required password input field. Choose a password that is at least 8 characters long.'}
                      required
                    />
                  </div>
                  <div className="form-group requiredFormGroupLabel">
                    <div className={requiredConfirmedPasswordInput ? 'requiredLabelRed input-label' : 'input-label'}>
                      {id !== undefined || requiredConfirmedPasswordInput ? 'Confirm Password' : 'Confirm Password *'}
                    </div>
                    <input
                      className={requiredConfirmedPasswordInput ? 'form-control requiredInput requiredConfirmPasswordInput' : 'form-control'}
                      type="password"
                      placeholder="********"
                      value={confirmedPassword}
                      onChange={(e) => handleConfirmedPasswordInput(e)}
                      aria-label={id
                        ? 'This is the required confirm password input field. If you have changed your password then you must confirm it.'
                        : 'This is the required confirm password input field. Please type in same password here.'}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <div className="input-label">Admin</div>
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={roleState.name === 'admin' || isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                      aria-label="admin checkbox"
                      disabled={roleState.name === 'admin'}
                    />
                  </div>
                </>
              ) : null
            }

            <div className="form-group addUser-dropdown-container requiredFormGroupLabel">
              <div
                className={
                  roleState.name === '' ? 'requiredLabelRed input-label' : 'input-label'
                }
              >
                {roleState.name === '' ? 'Role' : 'Role *'}
              </div>
              <Dropdown
                direction="down"
                isOpen={dropDownOpen}
                toggle={toggleDropDown}
                onClick={() => dropdownClick()}
                disabled={username === 'admin' && roleState.name === 'admin'}
              >
                <DropdownToggle
                  className={
                    roleState.name === ''
                      ? 'btn outlinedRequired-button dropdown-toggle'
                      : 'btn btn-secondary dropdown-toggle'
                  }
                  color="black"
                  caret
                >
                  {roleState.name === '' && dropDownOpen === false ? 'Select A Role' : roleState.name}
                </DropdownToggle>
                <DropdownMenu
                  persist
                >
                  {renderRolesItems()}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="form-group">
              <div className="input-label">Channels</div>
              <div className="checkbox-list">{renderChannelItems('channels')}</div>
            </div>
            {username !== 'GUEST' ? (
              <div className="form-group">
                <div className="input-label">Access Controls</div>
                <div className="checkbox-list">
                  {renderAccessControls('access-controls')}
                </div>
              </div>
            ) : null}
            {username !== 'GUEST' && (campuses?.length || campusAlerts?.length || events?.length || capMessages?.length) ? (
              <div>
                <div className="input-label">Mobile Activation</div>
                <div className="form-check" key="mobileCanActivate">
                  <input
                    className="checkbox"
                    type="checkbox"
                    id="canActivate"
                    checked={mobileActivation.canActivate}
                    onChange={
                      () => {
                        // mobileActivation.canActivate ? modalExpansion = true : modalExpansion = false;
                        setMobileActivation({ ...mobileActivation, canActivate: !mobileActivation.canActivate });
                        setModalExpansion(!modalExpansion);
                      }
                    }
                    aria-label={mobileActivation.canActivate
                      ? 'Do you want to uncheck canActivate for this user?'
                      : 'Do you want to check canActivate for this user?'}
                  />
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="canActivate" className="form-checkbox">
                    Allow user to activate mobile events?
                  </label>
                </div>
              </div>
            ) : null}
          </div>
          {username !== 'GUEST' && (campuses?.length || campusAlerts?.length || events?.length || capMessages?.length) ? (
            <div className={`form-group ${modalExpansion ? 'col' : ''}`}>
              {mobileActivation.canActivate ? renderMobileActivation() : null}
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <Button
            text="Cancel"
            onClick={() => { toggle(); setModalExpansion(false); }}
            className="cancel-button"
          />
          <Button
            text={id ? 'Edit User' : 'Add User'}
            disabled={validateUser(
              username,
              password,
              confirmedPassword,
              roleState.name,
              id,
              users,
            )}
            onClick={(
              e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
            ) => handleSubmit(e)}
          />
        </div>
      </div>
    </main>
  );
};

export default AddEditUser;
