import { CampusAlertType } from '../../Typings/userTypes';

export interface DistrictState {
  campuses: {
    id: string,
    name: string,
    address: string,
  }[];
  campusAlerts: {
    id: number,
    label: string,
    color: string,
    textColor: string,
  }[];
  events: {
    id: string,
    label: string,
    description: string,
  }[];
  capMessages: {
    id: number,
    label: string,
  }[];
}

export interface CampusAlert {
  id: number,
  label: string,
  color: string,
  textColor: string,
}

export interface Campuses {
  id: string,
  name: string,
  address: string,
}

export interface CapMessage {
  id: number,
  label: string,
}

export interface Events {
  id: string,
  label: string,
  description: string,
}

const initState: DistrictState = {
  campuses: [],
  campusAlerts: [],
  events: [],
  capMessages: [],
};

const districtReducer = (
  state = initState,
  action: {
    type: string, payload: DistrictState,
    payload2: CampusAlert | Campuses | CapMessage | Events
  },
): DistrictState => {
  switch (action.type) {
    case 'STORE_CAMPUSES':
      return {
        ...state,
        campuses: action.payload.campuses as { id: string, name: string, address: string, }[],
      };
    case 'STORE_CAMPUS_ALERTS':
      return {
        ...state,
        campusAlerts: action.payload.campusAlerts as { id: number, label: string, color: string, textColor: string, }[],
      };
    case 'STORE_CAP_MESSAGES':
      return {
        ...state,
        capMessages: action.payload.capMessages as { id: number, label: string, }[],
      };
    case 'STORE_EVENTS':
      return {
        ...state,
        events: action.payload.events as { id: string, label: string, description: string, }[],
      };

      // CAMPUS_ALERT CRUD
    case 'ADD_CAMPUS_ALERT':
      return {
        ...state,
        campusAlerts: [...state.campusAlerts.filter(
          (alert: CampusAlertType) => alert.id !== action.payload2.id,
        ), action.payload2] as CampusAlert[],
      };
    case 'UPDATE_CAMPUS_ALERT':
      return {
        ...state,
        campusAlerts: [...state.campusAlerts.filter(
          (alert: CampusAlertType) => alert.id !== action.payload2.id,
        ), action.payload2] as CampusAlert[],
      };
    case 'DELETE_CAMPUS_ALERT':
      return {
        ...state,
        campusAlerts: [...state.campusAlerts.filter(
          (alert: CampusAlertType) => alert.id !== action.payload2.id,
        )] as CampusAlert[],
      };

      // CAMPUS CRUD
    case 'ADD_CAMPUS':
      return {
        ...state,
        campuses: [...state.campuses.filter(
          (campus: Campuses) => campus.id !== action.payload2.id,
        ), action.payload2] as Campuses[],
      };
    case 'UPDATE_CAMPUS':
      return {
        ...state,
        campuses: [...state.campuses.filter(
          (campus: Campuses) => campus.id !== action.payload2.id,
        ), action.payload2] as Campuses[],
      };
    case 'DELETE_CAMPUS':
      return {
        ...state,
        campuses: [...state.campuses.filter(
          (campus: Campuses) => campus.id !== action.payload2.id,
        )] as Campuses[],
      };

      // CAP CRUD
    case 'ADD_CAP':
      return {
        ...state,
        capMessages: [...state.capMessages.filter(
          (cap: CapMessage) => cap.id !== action.payload2.id,
        ), action.payload2] as CapMessage[],
      };
    case 'UPDATE_CAP':
      return {
        ...state,
        capMessages: [...state.capMessages.filter(
          (cap: CapMessage) => cap.id !== action.payload2.id,
        ), action.payload2] as CapMessage[],
      };
    case 'DELETE_CAP':
      return {
        ...state,
        capMessages: [...state.capMessages.filter(
          (cap: CapMessage) => cap.id !== action.payload2.id,
        )] as CapMessage[],
      };

      // EVENT CRUD
    case 'ADD_EVENT':
      return {
        ...state,
        events: [...state.events.filter(
          (event: Events) => event.id !== action.payload2.id,
        ), action.payload2] as Events[],
      };
    case 'UPDATE_EVENT':
      return {
        ...state,
        events: [...state.events.filter(
          (event: Events) => event.id !== action.payload2.id,
        ), action.payload2] as Events[],
      };
    case 'DELETE_EVENT':
      return {
        ...state,
        events: [...state.events.filter(
          (event: Events) => event.id !== action.payload2.id,
        )] as Events[],
      };

    default:
      return state;
  }
};

export default districtReducer;
