import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import { RootState } from '../../Redux/reducers';
import { getRoles } from './adminLogic';
import channelLogic from '../Channels/channelLogic';
import { Role } from '../../Typings/adminTypes';
import { Channel } from '../../Typings/channelTypes';
import { ToastType } from '../../Typings/toastTypes';
import type SocketController from '../../Utils/SocketController';

export const validateRole = (roleName: string):boolean => {
  const testRoleOnlySpace = /[a-zA-Z0-9]/.test(roleName);
  if (!testRoleOnlySpace) return true;
  if (roleName === undefined || roleName === null || roleName === '') return true;
  return false;
};

// TODO: Handle and display errors on editing to user
export const handlePermissions = (e: React.ChangeEvent<HTMLInputElement> | undefined, setRole: (data: Role) => void, role: Role): void => {
  if (e) {
    if (role.permissions.includes(e.target.value)) {
      setRole({
        ...role,
        permissions: role.permissions.filter((cur: string) => cur !== e.target.value),
      });
    } else {
      setRole({
        ...role,
        permissions: [...role.permissions, e.target.value],
      });
    }
  }
};

export const handleRoleName = (
  e: React.ChangeEvent<HTMLInputElement>,
  role: Role, setRole: (data: Role) => void,
  setRequiredInput: (input: boolean) => void,
): void => {
  setRole({
    ...role,
    name: e.target.value,
  });
  if (e.target.value === '') setRequiredInput(true);
  if (e.target.value !== '') setRequiredInput(false);
};

export const handleChannels = (e: React.ChangeEvent<HTMLInputElement>, setRole: (data: Role) => void, role: Role): void => {
  if (role.channels.includes(e.target.value)) {
    setRole({
      ...role,
      channels: role.channels.filter((cur: string) => cur !== e.target.value),
    });
  } else {
    setRole({
      ...role,
      channels: [...role.channels, e.target.value],
    });
  }
};

// TODO: We need to inform the user of successful and unsuccesful requests
export const submitAddEditRole = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  data: Role,
  type: string,
  dispatch: (action: { type: string, payload?: Role[] | ToastType, payload2?: Role }) => void,
  toggle: () => void,
  socket: SocketController,
  setRoleErr: (msg: string) => void,
  id?: string,
): Promise<boolean> => {
  let res: AxiosResponse<Role>;
  if (!e) return false;
  e.preventDefault();
  setRoleErr('');
  //* ***** change redux state here ? or we can just change page back to admin  ******/
  if (data.channelsList) {
    // channelsList causes a cyclic object
    data.channelsList = null; // eslint-disable-line no-param-reassign
  }
  if (!data.permissions.length) {
    setRoleErr('One permission must be selected. If no permissions wanted, select `none`');
    return false;
  }
  if (!data.channels.length) {
    setRoleErr('One channel must be selected.');
    return false;
  }
  if (type === 'put' && id) {
    try {
      res = await axios.put(`/api/v2/core/roles/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}`,
        },
      });
      const errMessage = res.status === 401 ? 'Unauthorized' : `Error editing ${res.data.name} role`;
      if (res.status === 200) {
        socket.emitUpdateRole(res.data);
        toggle();
        return true;
      }
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'danger',
          heading: 'Role',
          message: `${errMessage}`,
          autoDismiss: true,
        },
      });
      return false;
    } catch (err) {
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'danger',
          heading: 'Role',
          message: `Error editing ${data.name} role`,
          autoDismiss: true,
        },
      });
      return false;
    }
  } else if (type === 'post') {
    try {
      const newRole = {
        channels: data.channels,
        name: data.name,
        permissions: data.permissions,
      };
      res = await axios.post('/api/v2/core/roles', newRole, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}`,
        },
      });
      const errMessage = res.status === 401 ? 'Unauthorized' : `Error adding ${res.data.name} role`;
      if (res.status === 201) {
        socket.emitNewRole(res.data);
        toggle();
        return true;
      }
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'danger',
          heading: 'Role',
          message: `${errMessage}`,
          autoDismiss: true,
        },
      });
      return false;
    } catch (err) {
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'danger',
          heading: 'Role',
          message: `Error adding ${data.name} role`,
          autoDismiss: true,
        },
      });
      return false;
    }
  }
  return false;
};

const AddEditRole = ({ id, toggle }: { id?: string, toggle: () => void }): JSX.Element => {
  const { roles, permissions, rolesVisited } = useSelector((state: RootState) => state.admin);
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const [role, setRole] = useState<Role>({
    _id: '', channels: [], name: '', permissions: [], channelsList: [],
  });
  const [requiredInput, setRequiredInput] = useState(false);
  const dispatch = useDispatch();
  const [roleErr, setRoleErr] = useState('');

  useEffect(() => {
    if (!rolesVisited || roles.length < 1) {
      getRoles(dispatch);
    }
    if (permissions.length < 1) {
      dispatch({ type: 'GET_PERMISSIONS', payload: {} });
    }
    if (!channelsVisited || !channels.length) {
      channelLogic.getChannels(dispatch);
    }
  }, []);

  useEffect(() => {
    // TODO: Handle case where id of role being looked for is not found in
    if (id && roles.length > 0) {
      const newRole = roles.find((cur: Role) => cur._id === id);
      if (newRole !== undefined) setRole(newRole);
    }
  }, [roles.length]);

  return (
    <main className="outerFormContainer">
      <form>
        <div className="form-group">
          <Input
            htmlFor="Role Name"
            ariaLabel={id
              ? `${role.name} is the current name for this role. Do you wish to change it?`
              : 'This is the required role name input field. Please type in name of role here and submit button will no longer be disabled.'}
            label={requiredInput ? 'Role Name' : 'Role Name *'}
            type="text"
            className={requiredInput ? 'form-control requiredInput' : 'form-control'}
            value={role.name || ''}
            onChange={(e) => handleRoleName(e, role, setRole, setRequiredInput)}
            required={!!requiredInput}
          />
        </div>
        <div className="form-group">
          <p className="input-label">Permissions *</p>
          {roleErr.length ? (
            <p className="errorText">
              *
              {roleErr}
              {' '}
              *
            </p>
          ) : null}
          <div className="checkbox-list">
            {permissions && permissions.map((cur: string) => (
              <div key={cur} className="form-check">
                <input
                  key={cur}
                  name="permission"
                  type="checkbox"
                  value={cur}
                  aria-label={role.permissions.includes(cur)
                    ? `Do you want to uncheck ${cur} permission for this role?`
                    : `Do you want to check ${cur} permission for this role?`}
                  checked={(role.name === 'admin' || role.permissions.length)
                    ? role.permissions.includes(cur) : false}
                  onChange={(e) => handlePermissions(e, setRole, role)}
                />
                <label htmlFor="permission" className="form-check-label form-checkbox">{cur}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          <p className="input-label">Channels *</p>
          <div className="checkbox-list">
            {channels && channels.map((cur: Channel) => (
              <div key={cur._id} className="form-check">
                <input
                  key={cur._id}
                  name="channel"
                  type="checkbox"
                  value={cur._id}
                  aria-label={role.channels.includes(cur._id)
                    ? `Do you want to uncheck ${cur.name} channel for this role?`
                    : `Do you want to check ${cur.name} channel for this role?`}
                  checked={(role.name === 'admin' || role.channels.length)
                    ? role.channels.includes(cur._id) : false}
                  onChange={(e) => handleChannels(e, setRole, role)}
                />
                <label htmlFor="channel" className="form-check-label form-checkbox">{cur.name}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="form-group">
          <Button text="Cancel" onClick={() => toggle()} className="cancel-button" />
          <Button
            text="Submit"
            onClick={(e) => submitAddEditRole(e, role, id ? 'put' : 'post', dispatch, toggle, socket, setRoleErr, id && id)}
            type="submit"
            disabled={validateRole(role.name)}
          />
        </div>
      </form>
    </main>
  );
};

export default AddEditRole;
