import { Alert, AlertDetail } from '../../Typings/alertTypes';
import { Channel } from '../../Typings/channelTypes';

export interface DashboardState {
  alerts: Alert[];
  filteredAlerts: boolean;
  totalAlerts: Alert[];
  activeUsers: number;
  alertDetail: AlertDetail | undefined;
  senderVisited: boolean;
}

const initState: DashboardState = {
  alerts: [],
  filteredAlerts: false,
  totalAlerts: [],
  activeUsers: 1,
  alertDetail: undefined,
  senderVisited: false,
};

const dashboardReducer = (
  state = initState,
  action: { type: string; payload: Alert[] | Channel[] | AlertDetail | number | boolean },
): DashboardState => {
  switch (action.type) {
    case 'GET_ALERTS_SUCCESS':
      return {
        ...state,
        alerts: action.payload as Alert[],
        filteredAlerts: false,
      };
    case 'GET_FILTERED_ALERTS_SUCCESS':
      return {
        ...state,
        alerts: action.payload as Alert[],
        filteredAlerts: true,
      };
    case 'ACTIVE_USERS':
      return {
        ...state,
        activeUsers: action.payload as number,
      };
    case 'GET_ALERT_DETAIL':
      return {
        ...state,
        alertDetail: action.payload as AlertDetail,
      };
    case 'CLEAR_ALERT_DETAIL':
      return {
        ...state,
        alertDetail: undefined,
      };
    case 'VISIT_SENDER':
      return {
        ...state,
        senderVisited: action.payload as boolean,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
