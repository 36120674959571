import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useDispatch, useSelector } from 'react-redux';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import defaultLogo from '../../assets/logo.png';
import { Styles } from '../../Typings/styleTypes';
import stylesLogic from './StylesLogic';
import Button from '../../Components/Button';
import { RootState } from '../../Redux/reducers';
import Status from '../../Components/Status';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

export const handleConfirmChangesClick = async (
  e:React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  style: Styles,
  files: any, // eslint-disable-line
  headerColor: string,
  styleExists: boolean,
  setStyle: (obj:Styles) => void,
  setFiles: (file:any) => void, // eslint-disable-line
  dispatch: (action: { type: string; payload?:string | Styles[] }) => void,
  setErrMessage: (errMess:string) => void,
  setHeaderColor: (color: string) => void,
  setStyleExists: (exists: boolean) => void,
): Promise<boolean> => {
  if (headerColor !== '') {
    const res = await stylesLogic.updateStyles(style._id, e, style, headerColor, setStyle, styleExists);
    if (res && !styleExists) stylesLogic.getStyle(setHeaderColor, style, setStyle, setStyleExists, dispatch);
  }
  if (files.length && files[0].file !== undefined) {
    stylesLogic.uploadLogoFile(files, setFiles, dispatch, setErrMessage);
  }
  return true;
};

const StylesContainer = (): JSX.Element => {
  const [style, setStyle] = useState<Styles>({
    _id: '14567788', backColor: '#FFFFFF', backText: 'random', headerColor: '#0075c9', headerText: 'random',
  });
  const [headerColor, setHeaderColor] = useState(style.headerColor);
  const { logo } = useSelector((state: RootState) => state.styles);
  const [files, setFiles] = useState<any>([]); // eslint-disable-line
  const [errMessage, setErrMessage] = useState('');
  const [styleExists, setStyleExists] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    stylesLogic.getStyle(setHeaderColor, style, setStyle, setStyleExists, dispatch);
    stylesLogic.getLogo(dispatch);
  }, []);

  return (
    <main className="outerFlexContainer stylePageMargin">
      <section>
        <div
          tabIndex={0} // eslint-disable-line
          aria-label={`Displays header background color, which is ${style.headerColor} , and displays the logo`}
          className="appStyleHeader"
          style={{ backgroundColor: style.headerColor }}
        >
          {logo === '' ? (
            <img
              tabIndex={0} // eslint-disable-line
              alt="default Valcom V-Alert logo displayed in the header"
              src={defaultLogo}
              className="defaultLogo"
            />
          )
            : (
              <img
                  tabIndex={0} // eslint-disable-line
                alt="custom logo displayed in the header"
                src={logo}
                className="img-fluid uploadedLogo"
              />
            )}
        </div>
        <div className="StylesPageContainer">
          <div className="leftHandStylePageSection">
            <div className="stylesPageHeading">
              <section>
                <header aria-label="App Style Page" className="sectionHeadingMobile">
                  <h1
                    tabIndex={0} // eslint-disable-line
                    className="stylePageHeaderText"
                  >
                    App Style Page
                  </h1>
                </header>
              </section>
              <h2
                tabIndex={0} // eslint-disable-line
                className="stylePageParagraphText"
              >
                Here you can customize the look of the mobile app for all your users
              </h2>
            </div>
            <div className="chromePickerAndButtonMobileContainer">
              <p
                tabIndex={0} // eslint-disable-line
              >
                Update Background:
              </p>
              <HexColorPicker
                aria-label="header-color"
                color={headerColor}
                onChange={(color) => stylesLogic.handleChange(color, setHeaderColor)}
              />
              <Button
                type="submit"
                ariaLabel="Confirm style changes on App Style page"
                className="primary-button confirmStylesBtn"
                text="Confirm Changes"
                onClick={
                  (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => handleConfirmChangesClick(
                    e, style, files, headerColor, styleExists, setStyle, setFiles, dispatch, setErrMessage, setHeaderColor, setStyleExists,
                  )
}
              />
            </div>
            <div className="uploadSection">
              <p
                tabIndex={0} // eslint-disable-line
              >
                Update Logo:
              </p>
              <FilePond
                files={files} // eslint-disable-line
                onupdatefiles={setFiles}
                instantUpload={false}
                name="files"
                dropOnElement
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/svg']}
              />
            </div>
          </div>
          <div className="chromePickerAndButtonContainer">
            <p
              tabIndex={0} // eslint-disable-line
            >
              Update Background:
            </p>
            <HexColorPicker
              aria-label="header-color"
              color={headerColor}
              onChange={(color) => stylesLogic.handleChange(color, setHeaderColor)}
            />
            <Button
              type="submit"
              ariaLabel="Confirm style changes"
              className="primary-button confirmStylesBtn"
              text="Confirm Changes"
              onClick={
                (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => handleConfirmChangesClick(
                  e, style, files, headerColor, styleExists, setStyle, setFiles, dispatch, setErrMessage, setHeaderColor, setStyleExists,
                )
}
            />
          </div>
        </div>
        <Status type="warning" message={errMessage} />
      </section>
    </main>
  );
};

export default StylesContainer;
