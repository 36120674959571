import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Modal, ModalBody,
} from 'reactstrap';
import { RootState } from '../../Redux/reducers';
import fileManagerLogic from './fileManagerLogic';
import Card from '../../Components/Card';
import { FileType } from '../../Typings/fileTypes';
import { mapChannelNames } from '../Admin/adminLogic';
import channelLogic from '../Channels/channelLogic';
import { Channel } from '../../Typings/channelTypes';
import DeleteModal from '../../Components/DeleteModal';
import AddEditModal from '../../Components/AddEditModal';
import AddEditFile from './AddEditFile';

export const renderFileCards = (
  displayFiles: FileType[],
  channels: Channel[],
  setDelId: (id: string) => void,
  setDelName: (name: string) => void,
  setShowDeleteModal: (x: boolean) => void,
  setEditId: (id: string) => void,
  setShowEditModal: (x: boolean) => void,
  toggle: (id?: string) => void,
  imageArr: { [key: string]: string | ArrayBuffer | null },
): JSX.Element[] => {
  const fileList = displayFiles.map((file: FileType) => {
    const fType = file.offline ? 'Offline' : 'Online';
    const fileContent = [
      {
        heading: file.name,
        data: [],
      },
      {
        heading: 'Channels',
        data: mapChannelNames(file.channels, channels),
      },
      {
        heading: 'File Type',
        data: [fType],
      },
    ];

    if (file.meta.mimetype.split('/')[0] === 'image') {
      fileContent.push({
        heading: 'Preview',
        data: [imageArr[file._id] as string],
      });
    }

    return (
      <Card
        key={file._id}
        id={file._id}
        name={file.name}
        handleDelete={(id) => { setDelId(id); setDelName(file.name); setShowDeleteModal(true); }}
        handleEdit={(id) => { setEditId(id); setShowEditModal(true); }}
        content={fileContent}
        toggle={toggle}
      />
    );
  });
  return fileList;
};

const FileManager = (): JSX.Element => {
  const dispatch = useDispatch();
  const { files, filesVisited } = useSelector((state: RootState) => state.files);
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const [showFileDelete, setShowFileDelete] = useState(false);
  const [delId, setDelId] = useState('');
  const [delName, setDelName] = useState('');
  const [editId, setEditId] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [image, setImage] = useState<string | ArrayBuffer | null>('');

  const [imageArr, setImageArr] = useState<{ [key: string]: string | ArrayBuffer | null }>({});

  const [open, setOpen] = useState(false);

  const toggleOpen = (id?: string) => {
    setOpen(!open);
    if (id) {
      setImage(id);
    }
  };

  useEffect(() => {
    if (!channelsVisited || !channels.length) channelLogic.getChannels(dispatch);
    if (!filesVisited || !files.length) fileManagerLogic.getFiles(dispatch);
  }, []);

  useEffect(() => {
    if (files) {
      files.forEach((file: FileType) => {
        fileManagerLogic.setImageArray(file, imageArr, dispatch);
      });
    } else {
      setImageArr({});
    }
  }, [files.length]);

  const toggleDeleteModal = () => {
    setDelId('');
    setDelName('');
    setShowFileDelete(!showFileDelete);
    return true;
  };

  const toggleShowAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const submitDeleteFile = async () => {
    if (!delId.length) return false;
    const res = await fileManagerLogic.makeDelete({ delId, delName }, dispatch, socket);
    return res;
  };

  return (
    <main className="outerContainer">
      <section>
        <header aria-label="File Manager Page" className="sectionHeading addSectionHeading">
          <h1
            tabIndex={0} // eslint-disable-line
          >
            File Manager
          </h1>
          <div className="addBtnContainer">
            <FontAwesomeIcon
              aria-label="Add File Button"
              role="button"
              onKeyPress={() => setShowAddModal(true)}
              tabIndex={0} // eslint-disable-line
              icon={faPlusCircle}
              color="#6DABFF"
              size="2x"
              className="addRoleBtn mr-3"
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </header>
      </section>
      { showFileDelete && (
      <DeleteModal
        open={showFileDelete}
        deleteTitle="File"
        deleteFunction={submitDeleteFile}
        toggleModal={toggleDeleteModal}
      />
      ) }
      { showAddModal && (
        <AddEditModal
          open={showAddModal}
          modalHeading="Add File"
          modalBody={<AddEditFile toggle={toggleShowAddModal} />}
          toggleModal={toggleShowAddModal}
        />
      )}
      { showEditModal && (
        <AddEditModal
          open={showEditModal}
          modalHeading="Edit File"
          modalBody={<AddEditFile toggle={toggleEditModal} id={editId} />}
          toggleModal={toggleEditModal}
        />
      )}

      <Modal isOpen={open} toggle={() => toggleOpen()}>
        <ModalBody>
          <div className="d-flex align-items-center justify-content-center">
            <img src={image as string} style={{ width: '50%' }} alt="" />
          </div>
        </ModalBody>
      </Modal>

      <div className="card-container">
        { files.length > 0
        && renderFileCards(files, channels, setDelId, setDelName, setShowFileDelete, setEditId, toggleEditModal, toggleOpen, imageArr) }
      </div>
    </main>
  );
};

export default FileManager;
