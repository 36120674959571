import axios, { AxiosResponse } from 'axios';
import { Devices } from '../Typings/deviceTypes';
import { ToastType } from '../Typings/toastTypes';

const deviceRoute = '/api/v2/device/devices';

export const fetchRegisteredDevices = async (
  dispatch: (action: { type: string, payload: number | Devices[] }) => void,
): Promise<boolean> => {
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` } };
  try {
    const res = await axios.get(deviceRoute, config);
    if (res.status === 200) {
      dispatch({ type: 'SET_REGISTERED_DEVICES', payload: res.data as Devices[] });
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const deleteRegisteredDevices = async (
  deviceId: string,
  dispatch: (data: { type: string, payload: string | ToastType }) => void,
): Promise<boolean> => {
  let res: AxiosResponse<Devices>;
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken') || ''}` } };
  try {
    res = await axios.delete(`${deviceRoute}/${deviceId}`, config); // delete updated via socket
  } catch (error) {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'danger',
        heading: 'Devices',
        message: `Error deleting device ${deviceId}`,
        autoDismiss: true,
      },
    });
    return false;
  }
  const errMessage = res.status === 401 ? 'Unauthorized' : `Error deleting device ${deviceId}`;
  if (res.status === 200) {
    return true;
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      toastType: 'danger',
      heading: 'Devices',
      message: `${errMessage}`,
      autoDismiss: true,
    },
  });
  return false;
};
