import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Components/Button';
import { RootState } from '../../Redux/reducers';

const DeviceList = (): JSX.Element => {
  const { selectedMapDevices, individualSelectedDevices } = useSelector((state: RootState) => state.sender);
  const [render, setRender] = useState<boolean>();
  const [isActive, setIsActive] = useState(false);
  const [btnTxt, setBtnTxt] = useState('Select Individual Devices');
  const dispatch = useDispatch();

  useEffect(() => {
    setRender(true);
  }, []);

  const handleClick = () => {
    if (btnTxt === 'Select Individual Devices') {
      setBtnTxt('Select All Devices');
      setIsActive(true);
    } else {
      setBtnTxt('Select Individual Devices');
      dispatch({ type: 'INDIVIDUAL_DEVICES', payload: [] });
      setIsActive(false);
    }
    return btnTxt;
  };

  const getValue = (e: any) => {//eslint-disable-line
    const value = e.target.value;//eslint-disable-line
    if (!individualSelectedDevices.includes(value)) {
      const individualDevicesSelectArr = [...individualSelectedDevices, value];
      dispatch({ type: 'INDIVIDUAL_DEVICES', payload: individualDevicesSelectArr });
    } else {
      const filteredDevices = individualSelectedDevices.filter((device) => device !== value);
      dispatch({ type: 'INDIVIDUAL_DEVICES', payload: filteredDevices });
    }
  };

  const deviceList = () => {
    const selectedDeviceList = selectedMapDevices.map((device) => {
      const usrLocLat = device.loc.coordinates[0];
      const usrLocLng = device.loc.coordinates[1];
      let usrName = 'Guest';
      if (device.profile !== undefined && device.profile !== null && device.profile.name) {
        usrName = device.profile.name;
      }

      return (
        <div key={device._id}>
          <div className="row">
            <div className="col-10 userCol">
              <label className="listItems" htmlFor={device._id}>
                <strong className="userName">{usrName}</strong>
                <br />
                <span className="location">
                  Lat:
                  {usrLocLat}
                  {' '}
                  Lng:
                  {usrLocLng}
                </span>
              </label>
            </div>
            <div className="col-2 checkboxCol">
              <input
                className="listCheckBox"
                type="checkbox"
                value={device._id}
                id={device._id}
                checked={individualSelectedDevices.includes(device._id)}
                onChange={getValue}
              />
              <br />
            </div>
          </div>
        </div>
      );
    });
    return (

      <div className="deviceList">
        <ul className="selectedDevices">{selectedDeviceList}</ul>
      </div>
    );
  };

  const devicesListBtn = () => {
    if (render) {
      return (
        <div className="deviceListBtn">
          <Button
            disabled={false}
            text={btnTxt}
            className="primary-button listBtn"
            type="button"
            onClick={handleClick}
          />
        </div>
      );
    }
    return true;
  };
  return (
    <div>
      <div>
        {devicesListBtn()}
      </div>
      <div className={isActive ? 'deviceList' : 'noDeviceList'}>
        {deviceList()}
      </div>
    </div>
  );
};
export default DeviceList;
