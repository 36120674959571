import { combineReducers } from 'redux';
import dashboardReducer from './dashboardReducer';
import channelReducer from './channelReducer';
import { loginReducer } from './loginReducer';
import { adminReducer } from './adminReducer';
import { socketReducer } from './socketReducer';
import { userReducer } from './userReducer';
import fileManagerReducer from './fileManagerReducer';
import toastReducer from './toastReducer';
import copyrightReducer from './copyrightReducer';
import stylesReducer from './stylesReducer';
import senderReducer from './senderReducer';
import districtReducer from './districtReducer';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  channel: channelReducer,
  files: fileManagerReducer,
  login: loginReducer,
  admin: adminReducer,
  socket: socketReducer,
  users: userReducer,
  toasts: toastReducer,
  copyrights: copyrightReducer,
  styles: stylesReducer,
  sender: senderReducer,
  district: districtReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
