import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import axios, { AxiosResponse } from 'axios';
import { Alert } from '../Typings/alertTypes';

type RenderProps = {
  month: Moment;
  onMonthSelect: (currentMonth: Moment, value: string) => void;
  onYearSelect: (currentYear: Moment, value: string) => void;
};

export const getAlertsByDate = async (
  date: Moment,
  setShowByDate: (x: boolean) => void,
  setDateAlerts: (alertArray: Alert[]) => void,
  setErrorMessage: (msg: string) => void,
): Promise<boolean> => {
  setErrorMessage('');
  const day = date.date();
  const month = date.month() + 1;
  const year = date.year();
  const dateString = `${year.toString()}-${month.toString()}-${day.toString()}`;
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  let res: AxiosResponse<{ alerts: Alert[] }>;
  try {
    res = await axios.get(`/api/v2/core/alerts/date/${dateString}`, config);
  } catch (err) {
    setDateAlerts([]);
    setErrorMessage('Error filtering alerts');
    return false;
  }
  if (res.status === 200) {
    if (res.data.alerts.length) setDateAlerts(res.data.alerts);
    else {
      setDateAlerts([]);
      setErrorMessage('There are no alerts found for the selected date');
    }
    setShowByDate(true);
    return true;
  }
  setDateAlerts([]);
  setErrorMessage('Error filtering alerts');
  return false;
};

export const renderMonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
}: RenderProps): JSX.Element => (
  <div className="calendar-heading">
    <div style={{ margin: '0 3px' }}>
      <select
        value={month.month()}
        onChange={(e) => onMonthSelect(month, e.target.value)}
        aria-label="month selector"
      >
        {moment.months().map((label, value) => (
          <option key={Math.random().toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
    <div className="calendar-year">
      <select
        value={month.year()}
        onChange={(e) => onYearSelect(month, e.target.value)}
        aria-label="year selector"
      >
        <option value={moment().year() - 2}>{moment().year() - 2}</option>
        <option value={moment().year() - 1}>{moment().year() - 1}</option>
        <option value={moment().year()}>{moment().year()}</option>
      </select>
    </div>
  </div>
);

const Calendar = ({ setAlerts, showByDate, setErrorMessage }: {
  setAlerts: (a: Alert[]) => void,
  showByDate: (val: boolean) => void,
  setErrorMessage: (msg: string) => void,
}): JSX.Element => {
  const [selected, setSelected] = useState<Moment | null>(null);
  const [focused, setFocused] = useState<boolean>(true);

  const setDateChange = async (date: Moment | null): Promise<boolean> => {
    if (selected === date) {
      setErrorMessage('');
      setAlerts([]);
      showByDate(false);
      setSelected(null);
      return true;
    }
    setSelected(date);
    if (date) await getAlertsByDate(date, showByDate, setAlerts, setErrorMessage);
    return true;
  };

  return (
    <DayPickerSingleDateController
      date={selected}
      focused={focused}
      onDateChange={(date) => setDateChange(date)}
      onFocusChange={(isFocused) => setFocused(!isFocused)}
      initialVisibleMonth={() => moment()}
      hideKeyboardShortcutsPanel
      // isDayHighlighted={(day: Moment) => day.isSame(moment(), 'day')}
      renderMonthElement={renderMonthElement}
    />
  );
};

export default Calendar;
