import React from 'react';

type PillProps = {
  content: string;
  textColor: string;
  backgroundColor: string;
};

const Pill = ({
  content,
  backgroundColor,
  textColor,
}: PillProps): JSX.Element => (
  <div style={{ backgroundColor }} className="pill">
    <p style={{ color: textColor, textAlign: 'center' }}>{content}</p>
  </div>
);

export const GeoPill = (): JSX.Element => (
  <div style={{ backgroundColor: '#088F8F' }} className="pill">
    <p style={{ color: '#ffffff', textAlign: 'center' }}>Geo Alert</p>
  </div>
);

export default Pill;
