import React from 'react';

type CopyrightProps = {
  model: string;
  version: string;
  copyright: string;
  tier: 'Pro' | 'Standard',
};

const Copyright = ({
  model,
  version,
  tier,
  copyright,
}: CopyrightProps): JSX.Element => (
  <section>
    <footer className="copyrightFooter">
      <p className="copyrightFooterContent">
        {`${model} ${version}-${tier} Copyright ©${copyright}`}
      </p>
    </footer>
  </section>
);

export default Copyright;
