import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen, faAngleDown, faAngleUp, faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Dispatch, SetStateAction } from '../Typings/setStateAction';

type ContentType = {
  heading: string;
  data: string[];
  button?: JSX.Element,
};

type CardProps = {
  content: ContentType[];
  name: string;
  id: string;
  handleEdit: (id: string) => void;
  handleDelete: (id: string, name:string) => void;
  type?: string;
  token?: string;
  hideDelete?: boolean,
  hideEdit?: boolean,
  setTokenState?: Dispatch<SetStateAction<string>>;
  toggle?: (id?:string) => void;
  ariaLabel?: string;
};

type ChannelCardProps = {
  content: ContentType[];
  id: string;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  backgroundColor: string;
  textColor: string;
  ariaLabel?: string;
};

export const renderChannelHeadings = (index: number, channel?: boolean): string => {
  if (channel) return 'channelCardHeading';
  if (index === 0) return 'cardHeading';
  return 'columnHeading';
};

const renderCardContent = (
  content: ContentType[], id: string, channel?: boolean, token?: boolean, toggle?: (file?: string) => void,
): JSX.Element[] => {
  const cont = content.map((item:ContentType, index:number) => (
    <div className="dataListContainer">
      <div className={channel ? renderChannelHeadings(index, channel) : renderChannelHeadings(index)}>
        <p>{item.heading}</p>
      </div>
      {item.button || null}
      {item.heading === 'Preview' ? (
        <img src={item.data[0]} style={{ width: '50px', height: '50px' }} onClick={() => toggle && toggle(item.data[0])} alt="" />) //eslint-disable-line
        : item.data.map((colData: string, i: number) => (
        <p // eslint-disable-line
          className={i === 0 ? 'headerData' : 'columnData'}
          onClick={(e) => (token && i === 0 ? e.stopPropagation() : false)}
          onKeyDown={(e) => (token && i === 0 ? e.stopPropagation() : false)}
        >
          {colData}
        </p>))}
    </div>
  ));
  return cont;
};

export const ChannelCard = ({
  content, id, handleEdit, handleDelete, backgroundColor, textColor, ariaLabel,
}: ChannelCardProps): JSX.Element => (
  <section
    className="card channelCardContainer"
    style={{
      backgroundColor,
      color: textColor,
    }}
    aria-label={`${ariaLabel} Channel Card`} // eslint-disable-line
  >
    <div className="cardNav">
      <div className="editDelete">
        <div
          aria-label="Edit Button"
          className="pr-3"
          onClick={(e) => { e.stopPropagation(); return handleEdit(id); }}
          onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation(); return handleEdit(id); } return false; }}
          role="button"
          tabIndex={0} // eslint-disable-line
        >
          <FontAwesomeIcon icon={faPen} color="grey" />
        </div>
        <div
          aria-label="Delete Button"
          className="cardDeleteBtn"
          onClick={(e) => { e.stopPropagation(); return handleDelete(id); }}
          onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation(); return handleDelete(id); } return false; }}
          role="button"
          tabIndex={0} // eslint-disable-line
        >
          <FontAwesomeIcon icon={faTimesCircle} size="lg" color="red" />
        </div>
      </div>
    </div>
    <div
      tabIndex={0} // eslint-disable-line
      className="contentContainer"
    >
      {renderCardContent(content, id, true)}
    </div>
  </section>
);

const Card = ({
  content, id, name, handleEdit, handleDelete, type, token, setTokenState, hideDelete, hideEdit, toggle, ariaLabel,
}: CardProps): JSX.Element => {
  const [trunc, setTrunc] = useState(true);
  const [displayed, setDisplayed] = useState(true);

  const handleClick = () => {
    if (setTokenState && token) {
      if (displayed) {
        setTokenState(token);
        setDisplayed(!displayed);
        setTrunc(!trunc);
      } else {
        setTokenState('******');
        setDisplayed(!displayed);
        setTrunc(!trunc);
      }
    }
  };

  const isToken = type !== undefined ? type === 'token' : false;
  return (
    <div
      className={trunc ? 'card cardContainerFixed' : 'card cardContainer'}
      onClick={() => ((token && setTokenState) ? handleClick() : setTrunc(!trunc))}
      onKeyDown={() => setTrunc(!trunc)}
      role="button"
      tabIndex={0} // eslint-disable-line
      key={`${id}card`}
      aria-label={ariaLabel}
    >
      <div className="cardNav">
        <div className="cardToggle"><FontAwesomeIcon icon={trunc ? faAngleDown : faAngleUp} color="#6DABFF" /></div>
        <div className="editDelete">
          {hideEdit ? <p className="pt-3" /> : (
            <div
              aria-label="Edit Button"
              className="pr-3"
              onClick={(e) => { e.stopPropagation(); return handleEdit(id); }}
              onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation(); return handleEdit(id); } return false; }}
              role="button"
              tabIndex={0} // eslint-disable-line
            >
              {
                (type && type === 'token')
                  ? <FontAwesomeIcon arka-label="refresh token button" icon={faRedo} color="#f0ad4e" className="" onClick={() => handleEdit} />
                  : <FontAwesomeIcon icon={faPen} color="#f0ad4e" />
              }
            </div>
          )}

          {
            hideDelete ? null : (
              <div
                aria-label="Delete Button"
                className="cardDeleteBtn"
                onClick={(e) => { e.stopPropagation(); return handleDelete(id, name); }}
                onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation(); return handleDelete(id, name); } return false; }}
                role="button"
                tabIndex={0} // eslint-disable-line
                key={`${id}delete`}
              >
                <FontAwesomeIcon icon={faTimesCircle} size="lg" color="red" />
              </div>
            )
          }
        </div>
      </div>
      <div
        tabIndex={0} // eslint-disable-line
        className="contentContainer outlineNone"
        key={`${id}content`}
      >
        {renderCardContent(content, id, false, isToken, toggle)}
      </div>
    </div>
  );
};

export default Card;
