import { FileType } from '../../Typings/fileTypes';

export interface FileState {
  files: FileType[];
  filesVisited: boolean;
}

const initState: FileState = {
  files: [],
  filesVisited: false,
};

const fileManagerReducer = (
  state = initState,
  action: { type: string; payload?: FileType[] | string; payload2?: FileType; payload3?: { id: string, url: string | ArrayBuffer | null } },
): FileState => {
  switch (action.type) {
    case 'GET_FILES':
      return {
        ...state,
        files: action.payload as FileType[],
      };
    case 'ADD_FILE':
      return {
        ...state,
        files: [...state.files, action.payload2] as FileType[],
      };
    case 'EDIT_FILE':
      return {
        ...state,
        files: [...state.files.filter((cur: FileType) => action.payload2 && cur._id !== action.payload2._id), action.payload2] as FileType[],
      };

    case 'DELETE_FILE':
      return {
        ...state,
        files: [...state.files.filter((cur: FileType) => cur._id !== action.payload)] as FileType[],
      };

    case 'VISIT_FILES':
      return {
        ...state,
        filesVisited: true,
      };

    case 'UPDATE_URL':
      return {
        ...state,
        files: state.files.map((file: FileType) => {
          if (action.payload3 && file._id === action.payload3.id) {
            file.url = action.payload3.url; // eslint-disable-line no-param-reassign
          }
          return file;
        }),
      };
    default:
      return state;
  }
};

export default fileManagerReducer;
