import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import {
  FeatureGroup as LeafletFeatureGroup, LatLng, LatLngExpression, Layer,
} from 'leaflet';
import {
  MapContainer, TileLayer, FeatureGroup, Circle,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

interface ChannelMapProps {
  id: string | undefined,
  mapCenter: { lat: number, lng: number },
  circleCoordinates: { lat: number, lon: number } | undefined,
  circleRadius: number | undefined,
  handleCircleCoordinatesChange: (newCoords: { lat: number, lon: number } | undefined) => void,
  handleCircleRadiusChange: (newRadius: number | undefined) => void,
}

const ChannelMap = (props: ChannelMapProps): JSX.Element | null => {
  const {
    id, mapCenter, circleCoordinates, circleRadius, handleCircleCoordinatesChange, handleCircleRadiusChange,
  } = props;
  const zoomValue = 5;
  const featureGroupRef = useRef<LeafletFeatureGroup>(null);
  const [currentLayers, setCurrentLayers] = useState<Layer[] | undefined>(undefined);

  useEffect(() => {
    if (currentLayers && currentLayers.length > 1) {
      featureGroupRef.current?.removeLayer(currentLayers[0]);
    }
  }, [currentLayers]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = useCallback((e: any) => {
    const center = e.layer.getLatLng() as LatLng;
    const radius = e.layer.getRadius() as number;
    handleCircleRadiusChange(radius);
    handleCircleCoordinatesChange({ lat: center.lat, lon: center.lng });
    setCurrentLayers(featureGroupRef.current?.getLayers());
  }, []);

  const onDelete = useCallback(() => {
    handleCircleRadiusChange(undefined);
    handleCircleCoordinatesChange(undefined);
    featureGroupRef.current?.clearLayers();
    setCurrentLayers(undefined);
  }, []);

  return (
    <MapContainer
      className="map_container"
      center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
      zoom={zoomValue}
      scrollWheelZoom
      style={{ height: '50vh', width: '25vw' }}
      zoomControl
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FeatureGroup ref={featureGroupRef}>
        {(id && circleCoordinates && circleRadius) ? (
          <Circle
            center={[circleCoordinates.lat, circleCoordinates.lon] as unknown as LatLngExpression}
            radius={circleRadius}
          />
        ) : null }
        <EditControl
          position="topright"
          onCreated={onCreate}
          onDeleted={onDelete}
          edit={{ remove: false, edit: false }}
          draw={{
            polygon: false,
            rectangle: false,
            polyline: false,
            circle: true,
            circlemarker: false,
            marker: false,
          }}
        />
      </FeatureGroup>
    </MapContainer>
  );
};

export default ChannelMap;
