import axios, { AxiosResponse } from 'axios';

import { Alert, AlertDetail } from '../../Typings/alertTypes';
import { Channel } from '../../Typings/channelTypes';

const alertUrl = '/api/v2/core/alerts';

interface AlertData {
  docs: Alert[];
  page: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalDocs: number;
}

export interface AlertFetchType {
  error: boolean;
  data?: AlertData;
}

interface AlertRes extends AxiosResponse{
  data: {
    docs: Alert[];
    page: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    totalDocs: number;
  };
}

export interface AlertDetailFetchType {
  error: boolean;
  data?: AlertDetail // AlertDetailData;
}

interface AlertDetailRes extends AxiosResponse {
  data: AlertDetail // { detail: AlertDetail }
}

const scrollUpToTop = (): boolean => {
  const myDiv = document.getElementById('alertContainer');
  if (myDiv !== null) myDiv.scrollTo({ top: 0 });
  return true;
};

const fetchAlerts = async (
  dispatch: (action: { type: string; payload: Alert[] }) => void, page: number,
): Promise<AlertFetchType> => {
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  const urlOptions = JSON.stringify({ page, limit: 10, sort: '-created_at' });
  try {
    const res: AlertRes = await axios.get(`${alertUrl}?paginate=${encodeURIComponent(urlOptions)}`, config);
    if (res.status === 200) {
      dispatch({ type: 'GET_ALERTS_SUCCESS', payload: res.data.docs });
      return { error: false, data: res.data };
    }
    return { error: true };
  } catch (e) {
    return { error: true };
  }
};

// ANCHOR
// We want an async fetch function
const fetchAlertDetail = async (
  dispatch: (action: { type: string; payload: AlertDetail }) => void, alertId: string,
): Promise<AlertDetailFetchType> => {
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  try {
    const res: AlertDetailRes = await axios.get(`${alertUrl}/${alertId}`, config);
    if (res.status === 200) {
      dispatch({ type: 'GET_ALERT_DETAIL', payload: res.data });
      return { error: false, data: res.data };
    }
    return { error: true };
  } catch (e) {
    return { error: true };
  }
};

const fetchQueriedAlerts = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
  dispatch: (action: { type: string; payload: Alert[] }) => void,
  page: number,
  channels: Channel[],
  messageFilter: string, // eslint-disable-line
  setErrorMessage: (message: string) => void,
): Promise<AlertFetchType> => {
  if (e !== undefined) e.preventDefault();
  const token = sessionStorage.getItem('authToken');
  const config = { headers: { Authorization: `Bearer ${token !== null ? token : ''}` } };
  const urlOptions = JSON.stringify({ page, limit: 10, sort: '-created_at' });
  if (channels.length) {
    const channelNames = channels.map((channel: Channel) => {
      const cNames = channel.name;
      return cNames;
    });
    try {
      const res: AxiosResponse = await axios.get(
        `${alertUrl}/channel/${channelNames}?paginate=${encodeURIComponent(urlOptions)}`, config, // eslint-disable-line 
      );
      if (res.status === 200) {
        dispatch({ type: 'GET_FILTERED_ALERTS_SUCCESS', payload: res.data.alerts as Alert[] });
        return { error: false, data: res.data as AlertData };
      }
      setErrorMessage('Error fetching alerts');
      return { error: true };
    } catch (err) {
      setErrorMessage('Error fetching alerts');
      return { error: true };
    }
  }
  try {
    const res: AxiosResponse = await axios.get(`${alertUrl}/filter/${messageFilter}?paginate=${encodeURIComponent(urlOptions)}`, config);
    if (res.status === 200) {
      dispatch({ type: 'GET_FILTERED_ALERTS_SUCCESS', payload: res.data.alerts as Alert[] });
      return { error: false, data: res.data as AlertData };
    }
    setErrorMessage('Error fetching alerts');
    return { error: true };
  } catch (err) {
    setErrorMessage('Error fetching alerts');
    return { error: true };
  }
};

const getAlertDetail = async ({
  dispatch, setSuccessfulStates, setErrorMessage, alertId,
}: {
  dispatch: (action: { type: string; payload: AlertDetail }) => void;
  setSuccessfulStates: () => boolean;
  setErrorMessage: (message: string) => void;
  alertId: string;
}): Promise<boolean> => {
  const res = await fetchAlertDetail(dispatch, alertId);
  if (!res.error) {
    setSuccessfulStates();
    scrollUpToTop();
    return true;
  }
  setErrorMessage('Error fetching alerts');
  return false;
};

const clearAlertDetail = ({ dispatch }: { dispatch: (action: { type: string }) => void }): boolean => {
  dispatch({ type: 'CLEAR_ALERT_DETAIL' });
  return true;
};

const getNextPageOfAlerts = async ({
  dispatch, setSuccessfulStates, setErrorMessage, current,
}: {
  dispatch: (action: { type: string; payload: Alert[] }) => void;
  setSuccessfulStates: (res: AlertFetchType) => boolean;
  setErrorMessage: (message: string) => void;
  current: number;
}): Promise<boolean> => {
  const res = await fetchAlerts(dispatch, current + 1);
  if (!res.error) {
    setSuccessfulStates(res);
    scrollUpToTop();
    return true;
  }
  setErrorMessage('Error fetching alerts');
  return false;
};

const getPrevPageOfAlerts = async ({
  dispatch, setSuccessfulStates, setErrorMessage, current,
}: {
  dispatch: (action: { type: string; payload: Alert[] }) => void;
  setSuccessfulStates: (res: AlertFetchType) => boolean;
  setErrorMessage: (message: string) => void;
  current: number;
}): Promise<boolean> => {
  const res = await fetchAlerts(dispatch, current - 1);
  if (!res.error) {
    setSuccessfulStates(res);
    scrollUpToTop();
    return true;
  }
  setErrorMessage('Error fetching alerts');
  return false;
};

const dashboardLogic = {
  fetchAlerts,
  fetchQueriedAlerts,
  fetchAlertDetail,
  getAlertDetail,
  clearAlertDetail,
  getNextPageOfAlerts,
  getPrevPageOfAlerts,
  scrollUpToTop,
};

export default dashboardLogic;
