import io from 'socket.io-client';
import jwt from 'jsonwebtoken';

class DistrictSocketHandler {
  constructor(dispatch) {
    this.client = io(`${process.env.REACT_APP_CORE_SOCKET_URL}`, {
      transports: ['websocket', 'polling'],
      path: `${process.env.REACT_APP_CORE_SOCKET_PATH}`,
      autoConnect: true,
    });
    this.setupSocket();
    this.dispatch = dispatch;
    this.connected = false;
  }

  setupSocket() {
    this.client.on('connect', () => {
      if (this.connected) console.log('connected to core-service socket server'); // eslint-disable-line

      // creating and emiting jwt to authenticate with server
      const token = jwt.sign({ type: 'webUi' }, process.env.REACT_APP_ARDAL_JWT_SECRET);
      this.client.emit('authenticate', token);
    });

    this.client.on('authenticated', () => {
      this.connected = true;
      this.initListeners();
      this.pullData();
    });

    // TODO placeholder to test alert data broadcast
    /*
     * Alert CRUD listeners
     */
    this.client.on('alertUpdated', (data) => {
      console.log('UPDATE_CAMPUS_ALERT', data);
      this.dispatch({ type: 'UPDATE_CAMPUS_ALERT', payload2: data });
    });
    this.client.on('alertCreated', (data) => {
      console.log('ADD_CAMPUS_ALERT', data);
      this.dispatch({ type: 'ADD_CAMPUS_ALERT', payload2: data });
    });
    this.client.on('alertDeleted', (data) => {
      console.log('DELETE_CAMPUS_ALERT', data);
      this.dispatch({ type: 'DELETE_CAMPUS_ALERT', payload2: data });
    });
    /*
     * Campus CRUD listeners
     */
    this.client.on('campusUpdated', (data) => {
      console.log('UPDATE_CAMPUS', data);
      this.dispatch({ type: 'UPDATE_CAMPUS', payload2: data });
    });
    this.client.on('campusCreated', (data) => {
      console.log('ADD_CAMPUS', data);
      this.dispatch({ type: 'ADD_CAMPUS', payload2: data });
    });
    this.client.on('campusDeleted', (data) => {
      console.log('DELETE_CAMPUS', data);
      this.dispatch({ type: 'DELETE_CAMPUS', payload2: data });
    });
    /*
     * Cap CRUD listeners
     */
    this.client.on('capUpdated', (data) => {
      console.log('UPDATE_CAP', data);
      this.dispatch({ type: 'UPDATE_CAP', payload2: data });
    });
    this.client.on('capCreated', (data) => {
      console.log('ADD_CAP', data);
      this.dispatch({ type: 'ADD_CAP', payload2: data });
    });
    this.client.on('capDeleted', (data) => {
      console.log('DELETE_CAP', data);
      this.dispatch({ type: 'DELETE_CAP', payload2: data });
    });

    /*
     * Event CRUD listeners
     */
    this.client.on('eventUpdated', (data) => {
      console.log('UPDATE_EVENT', data);
      this.dispatch({ type: 'UPDATE_EVENT', payload2: data });
    });
    this.client.on('eventCreated', (data) => {
      console.log('ADD_EVENT', data);
      this.dispatch({ type: 'ADD_EVENT', payload2: data });
    });
    this.client.on('eventDeleted', (data) => {
      console.log('DELETE_EVENT', data);
      this.dispatch({ type: 'DELETE_EVENT', payload2: data });
    });

    this.client.on('updatedUser', (data) => {
      // TODO remove this console log
      console.log('editing from core-service socket');
      console.log('userSocketData', data);
      this.dispatch({ type: 'EDIT_USER_SUCCESS', payload2: data });
    });

    this.client.on('disconnect', () => {
      this.connected = false;
      if (!this.connected) console.log('Disconnected from core-service socket server'); // eslint-disable-line
    });
  }

  initListeners() {
    this.client.on('forwardDistrictData', (districtData) => {
      if (Object.keys(districtData).length) {
        const {
          campuses, campusAlerts, events, capMessages,
        } = districtData;
        if (campuses) this.dispatch({ type: 'STORE_CAMPUSES', payload: { campuses } });
        if (campusAlerts) this.dispatch({ type: 'STORE_CAMPUS_ALERTS', payload: { campusAlerts } });
        if (events) this.dispatch({ type: 'STORE_EVENTS', payload: { events } });
        if (capMessages) this.dispatch({ type: 'STORE_CAP_MESSAGES', payload: { capMessages } });
      }
    });
  }

  pullData() {
    this.client.emit('pullDistrictData');
  }

  triggerItem(data) {
    this.client.emit('triggerItem', data);
    this.dispatch({
      type: 'ADD_TOAST',
      payload: {
        toastType: 'success',
        heading: 'Mobile Activation',
        message: `${data.type} sent`,
        autoDismiss: true,
      },
    });
  }
}

export default DistrictSocketHandler;
