import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Card,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { RootState } from '../../Redux/reducers';
import Status from '../../Components/Status';
import {
  Campus, CampusAlert, CapMessage, Event,
} from '../../Typings/mobileActivationTypes';
import Button from '../../Components/Button';
import DistrictSocketHandler from '../../Utils/DistrictSocketHandler';
import mobileActivationLogic from './MobileActivationLogic';

const CampusSection = ({
  isDropdownOpen,
  handleIsDropdownOpen,
  selectedCampus,
  handleCampusSelection,
  campuses,
}: {
  isDropdownOpen: boolean,
  handleIsDropdownOpen: () => void,
  campuses: Campus[] | null,
  selectedCampus: Campus | null,
  handleCampusSelection: (campus: Campus) => void,
}): JSX.Element => (
  <div className="mt-3 mb-3">
    <Dropdown isOpen={isDropdownOpen} toggle={handleIsDropdownOpen}>
      <DropdownToggle caret>{selectedCampus === null ? 'Select Campus' : selectedCampus.name}</DropdownToggle>
      <DropdownMenu>
        {campuses && campuses.length
          ? campuses.map((campus: Campus) => (
            <DropdownItem key={campus.id} onClick={(e) => handleCampusSelection(campus)}>{campus.name}</DropdownItem>
          )) : <Status message="There are no campuses to display" type="warning" />}
      </DropdownMenu>
    </Dropdown>
  </div>
);

const Cards = ({
  items,
  name,
  type,
  campusIds,
  districtSocket,
}: {
  items: Array<CampusAlert | CapMessage | Event>,
  name: string,
  type: 'alert' | 'CAP' | 'events',
  campusIds: number[],
  districtSocket: DistrictSocketHandler,
}): JSX.Element => (
  <section>
    <h2>{name}</h2>
    <section className="card-container-sm">
      <Card>
        <ListGroup>
          <div className="container-no-gutters">
            {items.length > 0
              ? items.map((item: CampusAlert | CapMessage | Event) => (
                <ListGroupItem key={`${item.id}`} id={item.id.toString()}>
                  <div className="row">
                    <div className="col-sm-4">{item.label}</div>
                    <div className="col-sm-4 offset-sm-4 text-right">
                      <Button
                        text="Send"
                        className="primary-button"
                        disabled={!(campusIds && campusIds.length > 0)}
                        onClick={() => mobileActivationLogic.sendItem(
                          type,
                          item.id.toString(),
                          campusIds,
                          districtSocket,
                        )}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              )) : <Status message={`No ${name} to display`} type="warning" />}
          </div>
        </ListGroup>
      </Card>
    </section>
  </section>
);

const MobileActivationContainer = (): JSX.Element => {
  const {
    campusAlerts, campuses, capMessages, events,
  } = useSelector((state: RootState) => state.district);
  const { districtSocket } = useSelector((state: RootState) => state.socket);
  const [selectedCampus, setSelectedCampus] = useState<Campus | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleIsDropdownOpen = useCallback(() => setIsDropdownOpen((prevState) => !prevState), []);
  const handleCampusSelection = useCallback((campus: Campus) => setSelectedCampus(campus), []);

  return (
    <main className="outerContainer">
      <section>
        <header
          aria-label="Mobile Activation Page"
          className="sectionHeading addSectionHeading"
        >
          <h1>Mobile Activation</h1>
        </header>
      </section>
      <CampusSection
        isDropdownOpen={isDropdownOpen}
        handleIsDropdownOpen={handleIsDropdownOpen}
        campuses={campuses}
        selectedCampus={selectedCampus}
        handleCampusSelection={handleCampusSelection}
      />
      <Cards
        name="Campus Alerts"
        type="alert"
        districtSocket={districtSocket}
        items={campusAlerts}
        campusIds={selectedCampus ? [selectedCampus.id as unknown as number] : []}
      />
      <Cards
        name="CAP Messages"
        type="CAP"
        districtSocket={districtSocket}
        items={capMessages}
        campusIds={selectedCampus ? [selectedCampus.id as unknown as number] : []}
      />
      <Cards
        name="Events"
        type="events"
        districtSocket={districtSocket}
        items={events}
        campusIds={selectedCampus ? [selectedCampus.id as unknown as number] : []}
      />
    </main>
  );
};

export default MobileActivationContainer;
