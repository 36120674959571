import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Components/Button';
import TokenContainer from './TokenContainer';
import { RootState } from '../../Redux/reducers';
import {
  createToken, downloadFile, restoreDB, getRoles, getTokens, deleteItem, renderCard,
} from './adminLogic';
import channelLogic from '../Channels/channelLogic';
import DeleteModal from '../../Components/DeleteModal';
import Status from '../../Components/Status';
import AddEditModal from '../../Components/AddEditModal';
import AddEditRole from './AddEditRole';

const valertTier = process.env.REACT_APP_VALERT_TIER;

const Admin = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    roles, tokens, rolesVisited, tokensVisited,
  } = useSelector((state: RootState) => state.admin);
  const { channels, channelsVisited } = useSelector((state: RootState) => state.channel);
  const { socket } = useSelector((state: RootState) => state.socket); // eslint-disable-line
  const [showRoleDelete, setshowRoleDelete] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [showTokenDelete, setShowTokenDelete] = useState(false);
  const [editId, setEditId] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [render, setRender] = useState(false);
  const decodedPermissions: string | null = sessionStorage.getItem('permissions');

  useEffect(() => {
    if (!rolesVisited || !roles.length) getRoles(dispatch);
    if (!channelsVisited || !channels.length) channelLogic.getChannels(dispatch);
    if (!tokensVisited || !tokens.length) getTokens(dispatch);
  }, [render]);

  const toggleRoleModal = () => {
    setshowRoleDelete(!showRoleDelete);
  };

  const toggleTokenModal = () => {
    setShowTokenDelete(!showTokenDelete);
  };

  const deleteRole = () => {
    deleteItem({ id, name }, 'roles', dispatch, socket);
  };

  const deleteToken = () => {
    deleteItem({ id, name }, 'tokens', dispatch, socket, setRender, render);
  };

  const showDeleteModal = (roleId: string, roleName: string) => {
    setId(roleId);
    setName(roleName);
    setshowRoleDelete(true);
  };

  const showTokenDeleteModal = (tokenId: string, tokenName: string) => {
    setId(tokenId);
    setName(tokenName);
    setShowTokenDelete(true);
  };

  const showEdit = (eId: string) => {
    setEditId(eId);
    setShowEditModal(true);
  };

  const toggleShowAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  return (
    <main className="outerFlexContainer">
      {showRoleDelete && <DeleteModal open={showRoleDelete} toggleModal={toggleRoleModal} deleteTitle="role" deleteFunction={deleteRole} />}

      {showTokenDelete && <DeleteModal open={showTokenDelete} toggleModal={toggleTokenModal} deleteTitle="token" deleteFunction={deleteToken} /> }

      { showAddModal && (
        <AddEditModal
          open={showAddModal}
          modalHeading="Add Role"
          modalBody={<AddEditRole toggle={toggleShowAddModal} />}
          toggleModal={toggleShowAddModal}
        />
      )}
      { showEditModal && (
        <AddEditModal
          open={showEditModal}
          modalHeading="Edit Role"
          modalBody={<AddEditRole toggle={toggleEditModal} id={editId} />}
          toggleModal={toggleEditModal}
        />
      )}

      <div className="table-container">
        <div aria-label="Admin Page" className="sectionHeading addSectionHeading">
          <h1
            tabIndex={0} // eslint-disable-line
          >
            Roles
          </h1>
          <div className="addBtnContainer">
            <FontAwesomeIcon
              aria-label="Add Role Button"
              role="button"
              tabIndex={0} // eslint-disable-line
              onKeyPress={() => setShowAddModal(true)}
              icon={faPlusCircle}
              color="#6DABFF"
              size="2x"
              className="addRoleBtn mr-3"
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </div>
        <section className="card-container">
          {roles.length ? renderCard(roles, history, showDeleteModal, channels, showEdit)
            : <Status message="There are no roles to display" type="warning" /> }
        </section>
        <section className="admin-buttons">
          {decodedPermissions && decodedPermissions.includes('transaction')
            ? (
              <>
                <Button ariaLabel="Download Database Button" onClick={() => downloadFile('db', dispatch)} text="Download Database" />
                <Button ariaLabel="Download All Logs Button" onClick={() => downloadFile('log', dispatch)} text="Download All Logs" />
                <Button
                  ariaLabel="Restore Database Button"
                  onClick={() => true}
                  text={(
                    <label htmlFor="file" className="input-file-label">
                      Restore Database
                      <input type="file" id="file" name="fle" onChange={(e) => restoreDB(e, dispatch)} className="input-file" />
                    </label>
                )}
                />
              </>
            )
            : null}
        </section>
      </div>
      {valertTier === 'pro' ? (
      <div className="token-container">
        <TokenContainer
          contents={tokens}
          createToken={createToken}
          reRender={render}
          reRenderFunc={setRender}
          showDelete={showTokenDeleteModal}
        />
      </div>
      ) : <></>}
    </main>
  );
};

export default Admin;
