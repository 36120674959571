import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { RootState } from '../../Redux/reducers';
import { Devices } from '../../Typings/deviceTypes';
import usersLogic from './usersLogic';
import { deleteRegisteredDevices } from '../../Utils/deviceUtils';
import { User } from '../../Typings/userTypes';

const DeviceDetailModal = (props:{ template: string }): JSX.Element => {
  const { template } = props;
  const { devices: allDevices } = useSelector((state: RootState) => state.sender);
  const dispatch = useDispatch();
  const [devices, setDevices] = useState<Devices[]>([]);
  const [derivativeUsers, setDerivativeUsers] = useState<User[]>([]);
  const handleDevicesChange = useCallback((update: Devices[]) => setDevices(update), []);
  const handleDerivativeUsersChange = useCallback((update: User[]) => setDerivativeUsers(update), []);

  const handleDelete = async (deviceId: string) => {
    await deleteRegisteredDevices(deviceId, dispatch);
  };

  useEffect(() => {
    const fetchDerivativeUsers = async (t: string) => {
      const res = await usersLogic.getDerivativeUsers(t);
      handleDerivativeUsersChange(res.data);
    };

    try {
      fetchDerivativeUsers(template);
    } catch (error) {
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          toastType: 'danger',
          heading: 'Users',
          message: `Error fetching derivative users of ${template}`,
          autoDismiss: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (derivativeUsers.length > 0 && allDevices.length > 0) {
      // If a device's user matches with any user in derivativeUsers, add that device to matchedDevices
      const matchedDevices = allDevices.filter((d) => undefined !== derivativeUsers.find((u) => u._id === d.user));
      handleDevicesChange(matchedDevices);
    }
  }, [allDevices, derivativeUsers]);

  if (devices.length > 0) {
    return (
      <main>
        <table className="table mainTable" style={{ marginTop: 0 }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Last Updated</th>
              <th>
                <div style={{ float: 'right' }}>Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {devices.map((device) => (
              <tr key={device._id}>
                <td>{device.profile && device.profile.name ? device.profile.name : '' }</td>
                <td>{device.profile && device.profile.email ? device.profile.email : ''}</td>
                <td>{moment(device.updated_at).format('MM-DD-YYYY hh:mm:ss A')}</td>
                <td>
                  <div
                    style={{ float: 'right' }}
                    aria-label="Delete Button"
                    className="cardDeleteBtn"
                    onClick={(e) => { e.stopPropagation(); return handleDelete(device._id); }}
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation(); return handleDelete(device._id); } return false; }}
                    role="button"
                    tabIndex={0}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} size="lg" color="red" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    );
  }
  return (
    <b>No devices available.</b>
  );
};

export default DeviceDetailModal;
