import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastType } from '../Typings/toastTypes';
import Toast from './Toast';

export const handleDeleteToasts = (
  toasts: ToastType[],
  toast: ToastType,
  dispatch: (action: { type: string, payload: ToastType }) => void,
):boolean => {
  if (toasts.includes(toast)) {
    dispatch({ type: 'DELETE_TOAST', payload: toast });
  }
  return true;
};

export const renderToasts = (toasts: ToastType[], dispatch: (action: { type: string, payload: ToastType }) => void): JSX.Element[] => {
  const renderedToasts = toasts.map((toast: ToastType) => (
    <Toast
      message={toast.message}
      heading={toast.heading}
      toastType={toast.toastType}
      autoDismiss={toast.autoDismiss || false}
      timeStamp={toast.timeStamp}
      handleDismiss={() => handleDeleteToasts(toasts, toast, dispatch)}
    />
  ));
  return renderedToasts;
};

const ToastSection = ({ toasts }: { toasts: ToastType[] }): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <div
      role="alert"
      style={{
        position: 'absolute', top: 60, right: 10, zIndex: 19,
      }}
    >
      {renderToasts(toasts, dispatch)}
    </div>
  );
};

export default ToastSection;
