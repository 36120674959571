import DistrictSocketHandler from '../../Utils/DistrictSocketHandler';
import SocketController from '../../Utils/SocketController';

interface SocketState {
  socket: SocketController | any; //eslint-disable-line
  districtSocket: DistrictSocketHandler | any; //eslint-disable-line
}

const initState = {
  socket: {},
  districtSocket: {},
};

export function socketReducer(
  state = initState,
  action: { type: string; payload: SocketController },
): SocketState {
  switch (action.type) {
    case 'NEW_SOCKET':
      return {
        ...state,
        socket: action.payload,
      };
    case 'NEW_DISTRICT_SOCKET':
      return {
        ...state,
        districtSocket: action.payload,
      };

    default:
      return state;
  }
}
